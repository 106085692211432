import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface InitState {
  showDocumentComponent: boolean
}

const initialState: InitState = {
  showDocumentComponent: false
};

export const showDocumentComponent = createSlice({
  name: 'showDocumentComponent',
  initialState,
  reducers: {
    setShowDocumentComponent: (state, action: PayloadAction<boolean>) => {
      state.showDocumentComponent = action.payload;
    }
  }
});

export const { setShowDocumentComponent } = showDocumentComponent.actions;

export default showDocumentComponent.reducer;
