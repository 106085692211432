import React, { type FC, useContext, useLayoutEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonComponent } from '../../index';
import { SectionSuccessModal } from '../../ModalSuccessView/SectionSuccesModal';
import { HeaderModalSign } from '../Header/HeaderModalSign';
import { InfoIcon } from '../../icons/InfoIcon';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { signDocument } from '../../../redux/reducers/document';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { selectUserDocument } from '../../../redux/selectors/document';
import { ProfileDialogContext } from '../../../pages/Profile/context/ProfileDialogContextProvider';
import { setShowDocumentComponent } from '../../../redux/reducers/showDocumentComponent';

import style from './SectionModalSign.module.css';

enum signatureElementsHeight {
  header = 72,
  clearSection = 72,
  footer = 73,
  mobileFooter = 113,
}

interface ISectionModalSign {
  setAlreadySignedDoc: (el: boolean) => void
}

export const SectionModalSign: FC<ISectionModalSign> = ({ setAlreadySignedDoc }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hideDialog } = useContext(ProfileDialogContext);
  const [screenWidth, screenHeight] = useWindowSize();
  const [footerText, setFooterText] = useState<string>(t('labels.draw_your_signature_below_and_click_to_complete'));
  const [isSignatureEmpty, setIsSignatureEmpty] = useState<boolean | null>(null);
  const [step, setStep] = useState<number>(1);
  const [signatureHeight, setSignatureHeight] = useState<number>(0)
  const [succesHeight, setSuccesHeight] = useState<number>(0);
  const signaturePadRef = useRef<SignaturePad | null>(null);
  const document = useAppSelector(selectUserDocument);

  const isMobile = screenWidth < 600;

  useLayoutEffect(() => {
    if (screenHeight <= 0 && screenWidth <= 0) return;
    if (screenHeight > 0 && screenWidth > 0) {
      const mobileSigantureHeight = screenHeight - (signatureElementsHeight.clearSection + signatureElementsHeight.mobileFooter + signatureElementsHeight.header);
      const mobileSuccessHeight = screenHeight - signatureElementsHeight.header;

      const signatureDynamicHeight = isMobile ? mobileSigantureHeight : 250;
      const succesDynamicHeight = isMobile ? mobileSuccessHeight : 400;

      setSignatureHeight(signatureDynamicHeight);
      setSuccesHeight(succesDynamicHeight);
    }
  }, [screenHeight, screenWidth])

  const checkIfSignatureIsEmpty = (): boolean | undefined => {
    if (signaturePadRef.current !== null) {
      const isEmpty = signaturePadRef.current.isEmpty();
      setFooterText(!isEmpty
        ? t('labels.draw_your_signature_below_and_click_to_complete')
        : t('labels.signature_not_drawn')
      );
      setIsSignatureEmpty(isEmpty);
      return isEmpty;
    }
    return undefined
  };

  const submitSignature = (): void => {
    if (signaturePadRef.current !== null) {
      const isEmpty = checkIfSignatureIsEmpty();
      if (!(isEmpty ?? false)) {
        const signatureDataUrl = signaturePadRef.current.toDataURL();
        dispatch(signDocument({
          signature: signatureDataUrl,
          documentId: document?._id ?? ''
        }));
        setAlreadySignedDoc(true);
        setStep(2);
      }
    }
  }

  const clearSignature = (): void => {
    if (signaturePadRef.current !== null) {
      signaturePadRef.current.clear();
      checkIfSignatureIsEmpty();
    }
  }

  const viewProfileHandler = (): void => {
    dispatch(setShowDocumentComponent(false));
    hideDialog();
  }

  const goToTradeHandler = (): void => {
    dispatch(setShowDocumentComponent(false));
    navigate('/')
  }

  const isHeightGreaterThanZero = signatureHeight > 0;

  return (
    <>
    {isHeightGreaterThanZero
      ? (<div className={style.wrapper} style={{ width: isMobile ? '100%' : '560px' }}>
        <HeaderModalSign setOpen={hideDialog} setStep={setStep}/>
      {
        step === 1
          ? (
          <div className={style.signatureContainer} style={{ height: isMobile ? `${signatureHeight}px` : '100%' }}>
            <SignaturePad
              ref={signaturePadRef}
              height={signatureHeight}
              options={{
                minWidth: 0.5,
                maxWidth: 0.5,
                penColor: '#000',
                backgroundColor: '#fff',
                dotSize: 0.5
              }}
            />
            <div className={style.btnContainer}>
              <div className={style.btn}>
                <ButtonComponent onClick={clearSignature} btnstyle="default">
                  {t('Clear Signature')}
                </ButtonComponent>
              </div>
            </div>
            <div className={style.footerWrapper}>
              <div className={style.footerTitleContainer}>
                {(isSignatureEmpty ?? false) && <InfoIcon fillColor="var(--CFD-theme-System-Error)" />}
                  <p className={style.footerTitle} style={{ color: isSignatureEmpty ? 'var(--CFD-theme-System-Error)' : 'var(--CFD-theme-System-OnSurfaceVariant)' }}>
                  {footerText}
                </p>
              </div>
              <div className={style.footerBtnContainer}>
                <ButtonComponent btnstyle="primary" disabled={false} onClick={submitSignature} customInlineStyles={{ padding: '0 4px' }}>
                  {t('labels.save_signature')}
                </ButtonComponent>
              </div>
            </div>
          </div>
            )
          : (
              <div className={style.seccesContainer} style={{ height: `${succesHeight}px` }}>
                  <SectionSuccessModal
                    title={t('labels.your_account_management_agreement_is_signed')}
                    content={t('labels.your_account_management_agreement_is_signed_content')}
                  />
                <div className={style.footerWrapper}>
                  <div className={style.footerBtnContainer}>
                    <ButtonComponent btnstyle="primary" variant='outlined' disabled={false} onClick={viewProfileHandler} customInlineStyles={{ padding: 0 }}>
                      {t('labels.view_profile')}
                    </ButtonComponent>
                  </div>
                  <div className={style.footerBtnContainer}>
                    <ButtonComponent btnstyle="primary" disabled={false} onClick={goToTradeHandler} customInlineStyles={{ padding: 0 }}>
                      {t('labels.go_to_trade')}
                    </ButtonComponent>
                  </div>
                </div>
              </div>
            )
      }
    </div>)
      : null}
    </>
  );
};
