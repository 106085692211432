import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { calculateDaysPassed } from '../../../../helper';
import { type IAccardionMainContent } from '../../../../interface/savingsPageInterfaces';
import { useTimeLeft } from '../../../../../../hooks/useTimeLeft';
import { TimeLeftSquares } from '../../../../../../components';

import style from './AccardionMainContent.module.css';

export const AccardionMainContent: FC<IAccardionMainContent> = ({
  investedAmount, currencySymbol, isFlexible, period, pct, createdAt, releaseTime, isReleased, cumulativeTotal
}) => {
  const { t } = useTranslation();
  const timeLeft = useTimeLeft({ releaseTime, isReleased });

  return (
    <div className={ style.accardionCellSummary }>
        <div className={ style.accardionCellWrapper }>
          <p className={ style.accardionCellText }>{ investedAmount }</p>
          <p className={ style.accardionCellText }>{ currencySymbol}</p>
        </div>
        {
          !(isFlexible ?? false)
            ? (
                <>
                  <div className={ style.accardionCellWrapper }>
                    <p className={ style.accardionCellText }>{ t(`savings_page.duration_types.${period}`) }</p>
                  </div>
                  <div className={ style.accardionCellWrapper }>
                    <p className={ style.accardionCellText }>{ pct }</p>
                    <p className={ style.accardionCellText }>%</p>
                  </div>
                  <div className={ style.accardionCellWrapper }>
                    <TimeLeftSquares timeLeft={ timeLeft } />
                  </div>
                  <div className={ style.accardionCellStatusWrapper }>
                    <div className={ isReleased ? `${style.statusUnlocked}` : `${style.statusLocked}` }>
                      <p className={ style.accardionStatusCellText }>
                        { isReleased
                          ? t('savings_page.table_body.accounts_tab.accordion_details.statuses.unlocked')
                          : t('savings_page.table_body.accounts_tab.accordion_details.statuses.locked')
                        }
                      </p>
                    </div>
                  </div>
                </>
              )
            : (
                <>
                   <div className={ style.accardionCellWrapper }>
                    <p className={ style.accardionCellText }>{ pct }</p>
                    <p className={ style.accardionCellText }>%</p>
                  </div>
                  <div className={ style.accardionCellWrapper }>
                    <p className={ `${style.accardionCellText} ${style.paddingDays}` }>{ calculateDaysPassed(createdAt as string) }</p>
                    <p className={ style.accardionCellText }>{ t('labels.days') }</p>
                  </div>
                  <div className={ style.accardionCellStatusWrapper }>
                    <div className={ style.statusActive }>
                      <p className={ style.accardionStatusCellText }>
                        {t('savings_page.table_body.accounts_tab.accordion_details.statuses.active')}
                      </p>
                    </div>
                  </div>
                </>
              )
        }
        <div className={ style.accardionCellWrapper }>
          <p
            className={
              !(isFlexible ?? false)
                ? `${style.accardionCellCumalitivTotalText} ${style.paddingCummalitivTotalLocked}`
                : `${style.accardionCellCumalitivTotalText} ${style.paddingCummalitivTotal}`
            }
          >
            { cumulativeTotal }
          </p>
          <p className={ style.accardionCellText }>{ currencySymbol }</p>
        </div>
      </div>
  )
}
