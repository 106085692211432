/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrapper, Container, Header } from '../components';
import { InputComponent, ButtonComponent, useSnackbar, type HandleOpenInterface } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../hooks/redux';
import { sendPasswordReset } from '../../../redux/reducers/user';
import { type PayloadInterface } from '../../../interfaces';
import styles from '../Auth.module.css';

export const ForgotPassword: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { handleOpen } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');
  const [emailData, setEmailData] = useState({ isError: false, inputErrorMsg: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (emailData.isError) {
      setEmailData({ isError: false, inputErrorMsg: '' });
    }
  }, [email]);

  const submit = async (): Promise<void> => {
    setIsLoading(true);
    const response = await dispatch(sendPasswordReset({ email, host: window.location.host }));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      setEmailData({ isError: true, inputErrorMsg: payload.error as string });
      setIsLoading(false);

      return;
    }

    const openParams: HandleOpenInterface = {
      message: t('login_page.reset_password_instructions', { email: payload.data as string }),
      actionText: '',
      autoClose: true
    }

    handleOpen(openParams);
    setIsLoading(false);
  }

  return (
    <Wrapper>
      <Header />
      <Container
        subtitle={<h2>{t('login_page.forgot_password')}</h2>}
        content={
          <>
            <div className={styles.containerBody__column}>
              <div className={styles.containerBody} style={emailData.inputErrorMsg ? { marginBottom: '16px' } : {}}>
                <label htmlFor="emailInput">{t('login_page.enter_email')}</label>
                <div className={styles.inputContainer}>
                  <InputComponent
                    sizeInput="small"
                    id="emailInput"
                    error={emailData.isError}
                    errorText={emailData.inputErrorMsg}
                    value={email}
                    setValue={setEmail}
                  />
                </div>
                <div className={ styles.emptyBox }></div>
              </div>
              <div className={styles.containerBodyBtn}>
                <div className={ styles.emptyBoxLeft }></div>
                  <div className={ styles.containerBtn }>
                    <ButtonComponent
                      variant='contained'
                      loading={isLoading}
                      onClick={() => { submit(); }}
                      customInlineStyles={{ textTransform: 'none' }}
                    >{t('login_page.get_reset_instructions')}</ButtonComponent>
                  </div>
                <div className={ styles.emptyBox }></div>
              </div>
              <div className={styles.containerBodyBtn}>
                <div className={ styles.emptyBoxLeft }></div>
                <div className={ styles.containerBtn }>
                <ButtonComponent
                  variant='text'
                  onClick={() => { navigate('/login'); }}
                >
                  {t('login_page.sign_in')}
                </ButtonComponent>
                </div>
                <div className={ styles.emptyBox }></div>
              </div>
            </div>
          </>
        }
      />
    </Wrapper>
  )
}
