import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

export interface IDocumentForSigning {
  status: boolean
  documentHtml: string
  isSigned?: boolean
  _id: string
}

interface IinitialState {
  document: IDocumentForSigning | null
  loading: boolean
  downloadLoading: boolean
  error: null | string
}

const initialState: IinitialState = {
  document: null,
  downloadLoading: false,
  loading: false,
  error: null as string | null
};

export const signDocument = createAsyncThunk(
  'documents/signDocument',
  async ({ signature, documentId }: { signature: string, documentId: string }) => {
    const response = await apiHelper({
      method: 'post',
      path: '/documents/sign-document',
      data: {
        signature,
        documentId
      }
    });
  });

export const getUserDocument = createAsyncThunk(
  'documents/getUserDocument',
  async () => {
    const response = await apiHelper({
      method: 'get',
      path: '/documents/user-document'
    });
    return response?.data?.document ?? null;
  });

export const downloadDocument = createAsyncThunk(
  'documents/downloadDocument',
  async ({ id, userId }: { id: string, userId: string }) => {
    const response = await apiHelper({
      method: 'get',
      path: `/documents/download-document/${id}/${userId}`,
      responseType: 'blob'
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', 'document.pdf');

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  });

const documentSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.document = action.payload;
      })
      .addCase(getUserDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(downloadDocument.pending, (state) => {
        state.downloadLoading = true;
      })
      .addCase(downloadDocument.fulfilled, (state) => {
        state.downloadLoading = false;
      })
      .addCase(downloadDocument.rejected, (state, action) => {
        state.downloadLoading = false;
        state.error = action.payload as string;
      });
  }
});

export default documentSlice.reducer;
