import React, { type FC, useState, useRef, useEffect, useLayoutEffect, type MouseEvent } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { ButtonComponent, type SetStateAction } from '../../components';
import { formatPreatyDate } from '../../pages/Savings/helper';
import { useWindowSize } from '../../hooks/useWindowSize';
import styles from './SelectWithDatePickerComponent.module.css';
import i18n from '../../i18n';
import { dayjsToLocaleMap } from '../../i18n/dayjs.config';

const convertTimestamp = (inputTimestamp: string | Date): string => {
  const date = new Date(inputTimestamp);
  date.setHours(0, 0, 0, 0);
  const timezoneOffset = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() - timezoneOffset);
  const isoString = date.toISOString();
  const formattedDate = isoString.slice(0, 19) + 'Z';

  return formattedDate;
}

interface ISelectWithDatePickerComponent {
  setFilterQuery?: (el: SetStateAction<any>) => void
  selectLabel: string
  setSelectLabel: (el: string) => void
  filterPointer: string
}

export const SelectWithDatePickerComponent: FC<ISelectWithDatePickerComponent> = ({ setFilterQuery, selectLabel, setSelectLabel, filterPointer }) => {
  const { t } = useTranslation();
  const [screenWidth] = useWindowSize();
  const [selectedDate, setSelectedDate] = useState<any>(dayjs(new Date()));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const isMobile = (screenWidth <= 650);

  const handleDateChange = (newValue: dayjs.Dayjs | null): void => {
    setSelectedDate(newValue);
  };

  const submitBtnHandler = (): void => {
    if (setFilterQuery !== undefined) {
      const convertedTime = convertTimestamp(selectedDate.$d as string);
      const preatyFormat = formatPreatyDate(convertedTime);
      setFilterQuery((prevState: any) => ({ ...prevState, [filterPointer]: convertedTime }))
      setSelectLabel(preatyFormat);
    }

    setIsDropdownOpen(false);
  }

  const toggleDropdown = (e: MouseEvent): void => {
    e.stopPropagation();
    setIsDropdownOpen((prevState) => !prevState);
  };

  const closeDropdown = (event: any): void => {
    if ((dropdownRef.current != null) &&
      !dropdownRef.current.contains(event.target as Node) &&
      (containerRef.current != null) &&
      !containerRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, []);

  useLayoutEffect(() => {
    if (isDropdownOpen && (dropdownRef.current !== null)) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const dropdownStyles: React.CSSProperties = {};

      if (dropdownRect.right > window.innerWidth) {
        dropdownStyles.right = '0';
        dropdownStyles.left = 'auto';
      } else {
        dropdownStyles.left = '0';
        dropdownStyles.right = 'auto';
      }

      if (isMobile) {
        dropdownStyles.top = '100%';
        dropdownStyles.bottom = 'auto';
      } else {
        if (dropdownRect.bottom > window.innerHeight) {
          dropdownStyles.bottom = '100%';
          dropdownStyles.top = 'auto';
        } else {
          dropdownStyles.top = '100%';
          dropdownStyles.bottom = 'auto';
        }
      }

      Object.assign(dropdownRef.current.style, dropdownStyles);
    }
  }, [isDropdownOpen, isMobile]);

  useEffect(() => {
    if (isDropdownOpen && isMobile && (dropdownRef.current !== null)) {
      const dropdownContent = dropdownRef.current;
      dropdownContent.scrollTop = dropdownContent.scrollHeight;
      const lastChild = dropdownContent.lastElementChild;
      if (lastChild !== null) {
        lastChild.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isDropdownOpen, isMobile]);

  return (
    <div className={ styles.selectContainer }>
      <div
        className={ styles.select }
        style={{ backgroundColor: `${!selectLabel.includes(' ') ? 'var(--CFD-theme-System-SecondaryContainer)' : ''}` }}
        onClick={ toggleDropdown }
        ref={ containerRef }
      >
        <span
          className={ styles.text }
          style={{ color: `${!selectLabel.includes(' ') ? 'var(--CFD-theme-System-OnSecondaryContainer)' : ''}` }}
        >{ selectLabel }</span>
        { isDropdownOpen
          ? <ArrowDropUpIcon onClick={ toggleDropdown } sx={{ width: '18px', height: '18px' }}/>
          : <ArrowDropDownIcon onClick={ toggleDropdown } sx={{ width: '18px', height: '18px' }}/> }
      </div>
      {isDropdownOpen && (
        <div className={ styles.dropdown } ref={ dropdownRef }>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjsToLocaleMap[i18n.language]}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={ selectedDate }
              onChange={ handleDateChange }
              sx={{
                backgroundColor: 'var(--CFD-theme-Surface-5)',
                borderTopRightRadius: '16px',
                borderTopLeftRadius: '16px',
                maxHeight: '320px',
                '& .MuiPickersDay-root': {
                  color: 'var(--CFD-theme-System-OnSurface)'
                },
                '& .MuiPickersCalendarHeader-label': {
                  color: 'var(--CFD-theme-System-OnSurface)'
                },
                '& .MuiPickersSlideTransition-root': {
                  color: 'var(--CFD-theme-System-OnSurface)'
                },
                '& .MuiTypography-root': {
                  color: 'var(--CFD-theme-System-OnSurface)'
                },
                '& .MuiPickersArrowSwitcher-root .MuiSvgIcon-root': {
                  color: 'var(--CFD-theme-System-OnSurface)'
                },
                '& .MuiPickersYear-yearButton': {
                  color: 'var(--CFD-theme-System-OnSurface)'
                },
                '& .MuiIconButton-root': {
                  color: 'var(--CFD-theme-System-OnSurface)'
                },
                '& .MuiPickersDay-root.Mui-selected': {
                  backgroundColor: 'var(--CFD-theme-System-Primary) !important',
                  color: 'var(--CFD-theme-System-OnPrimary)'
                },
                '& .MuiPickersDay-root:hover': {
                  backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_08)'
                },
                '& .MuiPickersDay-root.MuiPickersDay-today': {
                  backgroundColor: 'var(--CFD-theme-Surface-5) !important',
                  color: 'var(--CFD-theme-System-OnSurface)',
                  border: '1px solid var(--CFD-theme-System-Primary)'
                },
                '& .MuiPickersDay-root.MuiPickersDay-today:hover': {
                  backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_08) !important',
                  color: 'var(--CFD-theme-System-OnSurface)',
                  border: '1px solid var(--CFD-theme-System-Primary)'
                },
                '& .MuiPickersDay-root.Mui-selected:hover': {
                  backgroundColor: 'var(--CFD-theme-System-Primary)',
                  color: 'var(--CFD-theme-System-OnPrimary)',
                  opacity: '0.8'
                }
              }}
            />
          </LocalizationProvider>
          <div className={ styles.buttonContainer }>
            <div className={ styles.cancelButtonWrapper }>
              <ButtonComponent variant='text' onClick={() => { setIsDropdownOpen(false); } }>{ t('accounts_page.cancel') }</ButtonComponent>
            </div>
            <div className={ styles.submitButtonWrapper }>
              <ButtonComponent variant='text' onClick={ submitBtnHandler }>{ t('labels.submit') }</ButtonComponent>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
