import React, { useEffect, useState } from 'react'

import { BalanceChart } from './BalanceChart/BalanceChart'
import { TradingChart } from './TradingChart/TradingChart'
import { Settings } from './Settings/Settings'
import { Statistic } from './Statistic/Statistic'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { getTabsData } from '../../redux/reducers/profile'
import { ProfileElementHeight } from './helper';
import { useWindowSize } from '../../hooks/useWindowSize';
import { TransactionModalContext } from '../../components'
import { DocumentComponent } from './Settings/TermsAndConditions/DocumentComponent/DocumentComponent';
import { ProfileDialogContextProvider } from './context/ProfileDialogContext';
import { downloadDocument, getUserDocument } from '../../redux/reducers/document'
import { selectUserDocument } from '../../redux/selectors/document'
import { setShowDocumentComponent } from '../../redux/reducers/showDocumentComponent'
import { selectShowDocumentComponents } from '../../redux/selectors/showDocumentComponent'

import style from './Profile.module.css'

const localStorageProfileCurrency = 'profile_currency';

export const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const profileCurrencyStorage = localStorage.getItem(localStorageProfileCurrency);
  const [screenWidth, screenHeight] = useWindowSize();
  const [calcHeight, setCalcHeight] = useState<number>(0);
  const [profileCurrencySymbol, setProfileCurrencySymbol] = useState<string>(profileCurrencyStorage ?? '');
  const [alreadySignedDoc, setAlreadySignedDoc] = useState(false);

  const accounts = useAppSelector((state) => state.accounts);
  const user = useAppSelector((state) => state.user);
  const document = useAppSelector(selectUserDocument);
  const showDocumentComponent = useAppSelector(selectShowDocumentComponents);

  const isTableView = screenWidth <= 1145 && screenWidth > 1024;
  const isMobile = screenWidth <= 1024;

  const setOpenDocument = (el: boolean): void => {
    dispatch(setShowDocumentComponent(el));
  }

  const downloadDocumentHandler = (): void => {
    const documentNotExist = (document?._id === undefined || document === null);
    if (documentNotExist && user._id === undefined) return;
    dispatch(downloadDocument({
      id: document?._id ?? '',
      userId: user?._id ?? ''
    }))
  }

  useEffect(() => {
    if (accounts.length !== 0) {
      dispatch(getTabsData())
    }
  }, [accounts])

  useEffect(() => {
    const isDocumentNotExist = !((user?.signedDocumentEnable) ?? false) && user?.documentId === '' && user?.documentId === undefined;
    if (isDocumentNotExist) return;
    dispatch(getUserDocument())
  }, [user]);

  useEffect(() => {
    if (screenHeight > 0 && screenWidth > 0) {
      if (!isTableView) {
        const allPageContentHeight = ((ProfileElementHeight.Gap * 2) + ProfileElementHeight.BalanceChartSection +
          ProfileElementHeight.StatisticSection + ProfileElementHeight.TradingChartSection);
        setCalcHeight(Number(allPageContentHeight));
      }
      if (isTableView) {
        const allPageContentHeight = ((ProfileElementHeight.Gap * 2) + ProfileElementHeight.BalanceChartSection +
          ProfileElementHeight.TableStatisticSection + ProfileElementHeight.TradingChartSection);
        setCalcHeight(Number(allPageContentHeight));
      }
    }
  }, [screenHeight, screenWidth]);

  return (
    <>
      <ProfileDialogContextProvider>
        <div className={style.wrapper}>
          <div className={style.settingsContainer}>
            <Settings
              calcHeight={ calcHeight }
              isMobile={ isMobile }
              setProfileCurrencySymbol={setProfileCurrencySymbol}
              downloadDocumentHandler={downloadDocumentHandler}
              alreadySignedDoc={alreadySignedDoc}
            />
          </div>
          <div className={style.profileContainer}>
            <div className={style.statisticContainer}>
              <Statistic profileCurrencySymbol={profileCurrencySymbol}/>
            </div>
            <div className={style.tradingChartContainer}>
              <TradingChart />
            </div>
            <div className={style.balanceChartContainer}>
              <BalanceChart />
            </div>
          </div>
          <TransactionModalContext
            open={showDocumentComponent}
            setOpen={setOpenDocument}
            component={
              <DocumentComponent
                setOpenDocument={setOpenDocument}
                downloadDocumentHandler={downloadDocumentHandler}
                setAlreadySignedDoc={setAlreadySignedDoc}
              />
            }
          />
        </div>
      </ProfileDialogContextProvider>
    </>
  )
}
