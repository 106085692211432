import React, { type FC, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { type AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  type AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import { t } from 'i18next';

import { ProfileElementHeight } from '../helper';
import { CompleteProfileSettings } from './CompleteProfile/CompleteProfileSettings';
import { ProfileSettings } from './ProfileSettings/ProfileSettings';
import { PasswordSettings } from './PasswordSettings/PasswordSettings';
import { DividerComponent, ModalVerifications, Modal2FA } from '../../../components';
import { UserInfo } from './UserInfo/UserInfo';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { selectIsUserDataAvailable, selectUserEmail, selectUserFirstName, selectUserLastName, selectUserProfileImage } from '../../../redux/selectors/user';
import { TwoFASection } from './2FASection/TwoFASection';
import TwoFAIconProfile from '../../../assets/images/2FAIconProfile.svg';
import SettingsIcon from '../../../assets/images/SettingsIcon.svg';
import ContractIcon from '../../../assets/images/Contract.svg';
import { fetchUser2FAData } from '../../../redux/reducers/twoFactorAuthentication';
import { PlatformSettings } from './PlatformSettings/PlatformSettings';
import { TermsAndConditions } from './TermsAndConditions/TermsAndConditions';
import { selectUserDocument } from '../../../redux/selectors/document';

import style from './Settings.module.css'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: 'var(--CFD-theme-System-Surface)',
  borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />}
    {...props}
  />
))(() => ({
  backgroundColor: 'var(--CFD-theme-System-Surface)',
  flexDirection: 'row',
  borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)',
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_08)'
  },
  '& .MuiAccordionSummary-content': {
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  backgroundColor: 'var(--CFD-theme-System-Surface)',
  borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)'
}));

const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
  height: '24px',
  width: '24px',
  borderRadius: '100px',
  border: '1px solid transparent',
  color: 'var(--CFD-theme-System-OnSurface)',
  padding: '8px',

  '&:hover': {
    backgroundColor: 'var(--CFD-theme-State-layers-on-surface-variant-opacity_08)'
  }
});

interface ISettings {
  calcHeight: number
  isMobile: boolean
  setProfileCurrencySymbol: (el: string) => void
  downloadDocumentHandler: () => void
  alreadySignedDoc: boolean
}

export const Settings: FC<ISettings> = ({
  calcHeight,
  isMobile,
  setProfileCurrencySymbol,
  downloadDocumentHandler,
  alreadySignedDoc
}) => {
  const dispatch = useAppDispatch()
  const isUserDataAvailable = useAppSelector(selectIsUserDataAvailable);
  const { isVerificationEnabled } = useAppSelector((state) => state.user);
  const [expanded, setExpanded] = useState<string | false>('completeProfile');
  const [open2FAModal, setOpen2FAModal] = useState(false);
  const [openVerificationModal, setOpenVerificationModal] = useState(false);

  const userFirstName: string = useAppSelector(selectUserFirstName)
  const userLastName: string = useAppSelector(selectUserLastName)
  const userEmail: string = useAppSelector(selectUserEmail)
  const profileImage: string | null = useAppSelector(selectUserProfileImage)
  const user = useAppSelector((state) => state.user);
  const document = useAppSelector(selectUserDocument);

  const settingsHeight = calcHeight - ProfileElementHeight.Border;

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const userName = `${userFirstName} ${userLastName}`;

  const verificationModal = useMemo(() => (
    <ModalVerifications open={openVerificationModal} setOpen={setOpenVerificationModal} />
  ), [openVerificationModal]);

  const twoFAModal = useMemo(() => (
    <Modal2FA open={open2FAModal} setOpen={setOpen2FAModal} />
  ), [open2FAModal]);

  useEffect(() => {
    dispatch(fetchUser2FAData())
  }, [isUserDataAvailable]);

  const isUserCanSignedDocument = useMemo(() =>
    ((user?.signedDocumentEnable ?? false) &&
     user?.documentId !== '' &&
     user?.documentId !== undefined) &&
    (document?.status ?? false),
  [user, document]
  );

  return (
    <div className={ style.settingWrapper } style={{ height: isMobile ? '100%' : `${settingsHeight}px` }} >
      <header className={ style.settingHeader }>
        <UserInfo userName={userName} email={userEmail} profileImage={profileImage} />
      </header>
      <DividerComponent />
      <div>
        {(isVerificationEnabled ?? false) && <Accordion expanded={expanded === 'completeProfile'} onChange={handleChange('completeProfile')} >
          <AccordionSummary
            aria-controls="panel1d-content"
            expandIcon={<StyledExpandMoreIcon />}
            id="panel1d-header"
            sx={{ backgroundColor: expanded === 'completeProfile' ? 'var(--CFD-theme-State-layers-on-surface-opacity_12)' : '' }}
          >
            <div className={ style.settingsFirstSection }>
              <VerifiedUserOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
              <h3 className={ style.settingsFirstSectionTitle }>{t('labels.identity_verifications')}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <CompleteProfileSettings setOpenVerificationModal={ setOpenVerificationModal } />
          </AccordionDetails>
        </Accordion>}
        {isUserCanSignedDocument && <Accordion expanded={expanded === 'termsAndConditions'} onChange={handleChange('termsAndConditions')}>
          <AccordionSummary
            aria-controls="panel6d-content"
            expandIcon={<StyledExpandMoreIcon />}
            id="panel6d-header"
            sx={{ backgroundColor: expanded === 'termsAndConditions' ? 'var(--CFD-theme-State-layers-on-surface-opacity_12)' : '' }}
          >
            <div className={ style.settingsSecondSection }>
              <svg className={style.svg}>
                <use xlinkHref={`${ContractIcon}#settingTermsIcon`} />
              </svg>
              <h3 className={ style.settingsSecondSectionTitle }>{t('labels.terms_and_conditions_agreement')}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '0' }}>
            <TermsAndConditions
              downloadDocumentHandler={downloadDocumentHandler}
              alreadySignedDoc={alreadySignedDoc}
            />
          </AccordionDetails>
        </Accordion>}
        <Accordion expanded={expanded === 'twoFactor'} onChange={handleChange('twoFactor')}>
          <AccordionSummary
            expandIcon={<StyledExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
            sx={{ backgroundColor: expanded === 'twoFactor' ? 'var(--CFD-theme-State-layers-on-surface-opacity_12)' : '' }}
          >
            <div className={ style.settingsFirstSection }>
              <svg className={style.svg}>
                <use xlinkHref={`${TwoFAIconProfile}#2FAProfile`} />
              </svg>
              <h3 className={ style.settingsFirstSectionTitle }>{t('profile_page.2fa_modal.header.title')}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <TwoFASection setOpen2FAModal={ setOpen2FAModal }/>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'profile'} onChange={handleChange('profile')}>
          <AccordionSummary
            expandIcon={<StyledExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
            sx={{ backgroundColor: expanded === 'profile' ? 'var(--CFD-theme-State-layers-on-surface-opacity_12)' : '' }}
          >
            <div className={ style.settingsSecondSection }>
              <PersonIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
              <h3 className={ style.settingsSecondSectionTitle }>{t('profile_page.settings.subtitles.profile')}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <ProfileSettings />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'password'} onChange={handleChange('password')}>
          <AccordionSummary
            aria-controls="panel4d-content"
            expandIcon={<StyledExpandMoreIcon />}
            id="panel4d-header"
            sx={{ backgroundColor: expanded === 'password' ? 'var(--CFD-theme-State-layers-on-surface-opacity_12)' : '' }}
          >
            <div className={ style.settingsSecondSection }>
              <PasswordOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
              <h3 className={ style.settingsSecondSectionTitle }>{t('profile_page.settings.subtitles.password')}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <PasswordSettings />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'settings'} onChange={handleChange('settings')}>
          <AccordionSummary
            aria-controls="panel5d-content"
            expandIcon={<StyledExpandMoreIcon />}
            id="panel5d-header"
            sx={{ backgroundColor: expanded === 'settings' ? 'var(--CFD-theme-State-layers-on-surface-opacity_12)' : '' }}
          >
            <div className={ style.settingsSecondSection }>
              <svg className={style.svg}>
                <use xlinkHref={`${SettingsIcon}#settingIcon`} />
              </svg>
              <h3 className={ style.settingsSecondSectionTitle }>{t('profile_page.labels.settings')}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '0' }}>
            <PlatformSettings setProfileCurrencySymbol={setProfileCurrencySymbol}/>
          </AccordionDetails>
        </Accordion>
      </div>
      {verificationModal}
      {twoFAModal}
    </div>
  )
}
