import React, { type FC, useState, useContext } from 'react';
import { DialogContext } from '../context/DialogContextProvider';
import { ButtonComponent } from '../../../components';
import { NumberInput } from '../components/BuySell/components/NumberInput';
import styles from './modals.module.css';
import { useTranslation } from 'react-i18next';

interface IContentProps {
  entryPrice: number
  liqPrice: number
  takeProfit: number
  stopLoss: number
  isLoading: boolean
  onClick: (takeProfit: number, stopLoss: number) => void
}

export const EditModalContent: FC<IContentProps> = ({ entryPrice, liqPrice, isLoading, takeProfit, stopLoss, onClick }): JSX.Element => {
  const { hideDialog } = useContext(DialogContext);
  const { t } = useTranslation();
  const [takeProfitEditModal, setTakeProfitEditModal] = useState<number | string>(takeProfit ?? 0);
  const [stopLossEditModal, setStopLossEditModal] = useState<number | string>(stopLoss ?? 0);

  return (
    <>
        <ul className={styles.editDialogContent}>
          <li>
              <span className={styles.label}>{t('labels.entry_price')}</span>
              <span className={styles.value}>{`${entryPrice} USD`}</span>
          </li>
          {/* <li>
          <DividerComponent />
          </li> */}
          {/* <li>
              <span className={styles.label}>Liq. Price</span>
              <span className={styles.value}>{`${liqPrice} USD`}</span>
          </li> */}
        </ul>
        <div className={styles.inputsContainer}>
            <div>
                <NumberInput value={takeProfitEditModal} onChange={(value) => { setTakeProfitEditModal(value); }} id='tp' label={t('labels.take_profit')} />
            </div>
            <div>
                <NumberInput value={stopLossEditModal} onChange={(value) => { setStopLossEditModal(value); }} id="sl" label={t('labels.stop_loss')} />
            </div>
        </div>
        <div className={styles.editDialogFooter}>
            <ButtonComponent btnstyle='primary' variant="text" onClick={hideDialog}>{t('accounts_page.cancel')}</ButtonComponent>
            <ButtonComponent btnstyle='primary' variant="text" loading={isLoading} onClick={() => { onClick(Number(takeProfitEditModal), Number(stopLossEditModal)); }}>{t('accounts_page.confirm')}</ButtonComponent>
        </div>
    </>
  );
}
