import React, { useContext, useEffect, useState, type FC } from 'react';
import { t } from 'i18next';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { ButtonComponent, CheckboxComponent, ProgressComponent } from '../../../../../components';
import { SignDocumentIcon } from '../../../../../components/icons/SignDocumentIcon';
import { ProfileElementHeight } from '../../../helper';
import { ProfileDialogContext } from '../../../context/ProfileDialogContextProvider';
import { SectionModalSign } from '../../../../../components/ModalSign/Section/SectionModalSign';
import { useAppSelector } from '../../../../../hooks/redux';
import { selectUserDocument, selectUserDocumentDownloadStatus } from '../../../../../redux/selectors/document';
import { selectShowDocumentComponents } from '../../../../../redux/selectors/showDocumentComponent';

import style from './DocumentComponent.module.css';

interface IDocumentComponent {
  setOpenDocument: (el: boolean) => void
  downloadDocumentHandler: () => void
  setAlreadySignedDoc: (el: boolean) => void
}

export const DocumentComponent: FC<IDocumentComponent> = ({ setOpenDocument, downloadDocumentHandler, setAlreadySignedDoc }) => {
  const [screenWidth] = useWindowSize();
  const { showDialog } = useContext(ProfileDialogContext);
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);
  const userDocument = useAppSelector(selectUserDocument);
  const downloadStatus = useAppSelector(selectUserDocumentDownloadStatus);

  const showDocumentComponent = useAppSelector(selectShowDocumentComponents);

  const isMobile = screenWidth <= 599;
  const headerAndFooter = ProfileElementHeight.DocumentSectionFooter + ProfileElementHeight.DocumentSectionHeader;
  const color = disabledBtn ? 'var(--CFD-theme-System-OnTertiary)' : 'var(--CFD-theme-System-OnSurface)';

  const btnHandler = (): void => {
    showDialog({
      bodyContent: <SectionModalSign
        setAlreadySignedDoc={setAlreadySignedDoc}
      />
    });
  }

  useEffect(() => {
    document.body.style.overflow = showDocumentComponent ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [showDocumentComponent])

  const checkBoxHandler = (): void => {
    setDisabledBtn((prevValue) => !prevValue);
    setCheckboxValue((prevValue) => !prevValue);
  }

  return (
    <div className={style.mainWrapper}>
      <div className={style.header}>
        <p className={style.headerTitle}>{t('labels.terms_and_conditions_agreement')}</p>
        <div className={style.headerBtnContainer}>
          { downloadStatus
            ? (<div className={style.loadingContainer}>
                <ProgressComponent type='circular' sx={{ width: '32px !important', height: '32px !important' }}/>
              </div>)
            : (<button className={style.btn} onClick={downloadDocumentHandler}>
              <SaveAltIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
            </button>)
          }
          <button className={style.btn} onClick={() => { setOpenDocument(false); }}>
            <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
          </button>
        </div>
      </div>
      <div
        className={style.bodyWrapper}
        style={{
          maxHeight: isMobile ? `calc(100dvh - ${headerAndFooter}px)` : `calc(100vh - ${headerAndFooter}px)`,
          height: isMobile ? `calc(100dvh - ${headerAndFooter}px)` : `calc(100vh - ${headerAndFooter}px)`
        }}
      >
        <div style={{ width: '100%', overflow: 'hidden', backgroundColor: 'white', height: `calc(100vh - ${headerAndFooter}px)` }}>
          <iframe srcDoc={`${userDocument?.documentHtml}`} style={{ width: '100%', height: '100%' }}/>
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.agreeContainer}>
          <CheckboxComponent checked={checkboxValue} disabled={userDocument?.isSigned ?? false} onChange={checkBoxHandler} />
          <p className={`${!(userDocument?.isSigned ?? false) ? style.agreeTitle : style.disabledAgreeTitle}`}>{t('labels.i_agree_to_the_following_terms_and_conditions')}</p>
        </div>
        <div className={style.footerBtnContainer}>
          <ButtonComponent onClick={btnHandler} disabled={!disabledBtn}>
            <div className={style.btnContent}>
              <SignDocumentIcon disabled={!disabledBtn} color={color}/>
              <p
                className={style.btnTitle}
                style={{ color }}
              >{t('labels.sign_the_document')}</p>
            </div>
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
}
