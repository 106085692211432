import React, { useMemo, type FC } from 'react';
import { t } from 'i18next';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { useAppSelector } from '../../../../hooks/redux';
import { LogoIcon } from '../../../icons/LogoIcon';
import { accountSelectBtnIconStyle, FabComponent, useTheme } from '../../../../components';
import reciveDeposit from '../../../../assets/images/recive_deposit.svg';
import { getCurrencySymbol, toFixed } from '../../../../helpers/util';

import style from '../HeaderLiveChatComponent.module.css';

interface IMobileHeaderLiveChatComponent {
  setChatOpen: (el: boolean) => void
  setOpenDeposit: (el: boolean) => void
  setOpenMenu: (el: boolean) => void
  setOpenSelectAccounts: (el: boolean) => void
}

export const MobileHeaderLiveChatComponent: FC<IMobileHeaderLiveChatComponent> = ({ setChatOpen, setOpenDeposit = () => {}, setOpenMenu, setOpenSelectAccounts }) => {
  const { theme } = useTheme();
  const { firstName, brand } = useAppSelector((state) => state.user);
  const accounts = useAppSelector((state) => state.accounts);

  const selectedAccount = useMemo(() => (
    accounts.find((account) => account.isActive) ?? null
  ), [accounts]);

  const symbolIcon = useMemo(() => (getCurrencySymbol(selectedAccount?.cfdAccountCurrency.symbol ?? '')), [selectedAccount])

  const depositBtn = (): void => {
    setOpenDeposit(true);
    setChatOpen(false);
  }

  const openMenuHandler = (): void => {
    setOpenMenu(true);
    setChatOpen(false);
  }

  const selectAccountHandler = (): void => {
    setOpenSelectAccounts(true);
    setChatOpen(false);
  }

  return (
    <>
    <div className={`${style.headerLiveMobileChat} ${style[`wrapper-${theme}`]}`}>
        <div className={ style.headerLiveMobileLogo }>
          {
            ((brand?.landingUrl) != null)
              ? <a href={ brand?.landingUrl } target="_blank" rel="noreferrer"><LogoIcon /></a>
              : <LogoIcon />
          }
        </div>
        <div className={ style.headerLiveMobileOptions }>
          <div className={ style.headerLiveMobileBtn }>
            <FabComponent variant="extended" size='small' onClick={ depositBtn }>
              <svg className={ style.svg }>
                <use xlinkHref={`${reciveDeposit}#reciveDeposit`}/>
              </svg>
              <span className={style.headerTitle}>{t('header.deposit_button_title')}</span>
            </FabComponent>
          </div>
          {
            accounts.length > 0 && (
              <button onClick={selectAccountHandler} className={ style.infoWrapperSelectBtn }>
                <AccountBalanceWalletIcon sx={ accountSelectBtnIconStyle }/>
                <div className={ style.infoWrapperSelectBtnContent }>
                  <p className={ style.infoWrapperSelectBalance }>{`${symbolIcon} ${toFixed(selectedAccount?.balance ?? 0, 2)}`}</p>
                  <p className={ style.infoWrapperSelectTitle }>
                    { selectedAccount?.type.name ?? '' } {t('labels.account')} { selectedAccount?.cfdAccountCurrency?.symbol ?? '' }
                  </p>
                </div>
                <div className={ style.infoWrapperSelectIconMobile }>
                  <KeyboardArrowRightIcon sx={ accountSelectBtnIconStyle } />
                </div>
              </button>
            )
          }
          <button className={style.headerMenuBtn} onClick={openMenuHandler}>
            {
              (firstName !== undefined && firstName.length > 0) && (
                <div className={ style.headerLiveMobileUserIcon }>
                  { firstName[0] }
                </div>
              )
            }
          </button>
        </div>
      </div>
    </>
  )
}
