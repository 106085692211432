import React, { type FC } from 'react';
import { TableCell, TableRow, TableBody, styled } from '@mui/material';

import style from './EmptyTableComponent.module.css';
import { useTranslation } from 'react-i18next';

export const StyledTableCell = styled(TableCell)(() => ({
  backgroundColor: 'var(--CFD-theme-System-Surface)',
  color: 'var(--CFD-theme-System-OnSurface)',
  textAlign: 'center',
  borderBottom: 'none'
}));

interface InterfaceEmptyTableComponent {
  columnCount: number
  mainText?: string
  subText?: string
  height?: string
  padding?: string
}

export const EmptyTableComponent: FC<InterfaceEmptyTableComponent> = ({
  columnCount,
  mainText = 'table.no_data',
  subText = 'table.nothing_found',
  height = '210px',
  padding = '16px'
}) => {
  const { t } = useTranslation();
  return (
    <TableBody sx={{ backgroundColor: 'var(--CFD-theme-System-Surface) !important' }}>
      <TableRow sx={{ backgroundColor: 'var(--CFD-theme-System-Surface) !important' }}>
        <StyledTableCell colSpan={ columnCount } sx={{ height: `${height}`, padding }} >
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="96" viewBox="0 0 64 96" fill="none">
            <path d="M64 95.3335V6.66679C64 6.2986 63.7015 6.00012 63.3333 6.00012H48.6667C48.2985 6.00012 48 6.2986 48 6.66679V13.3335C48 13.7016 47.7015 14.0001 47.3333 14.0001H16.6667C16.2985 14.0001 16 13.7016 16 13.3335V6.66679C16 6.2986 15.7015 6.00012 15.3333 6.00012H0.666667C0.298477 6.00012 0 6.2986 0 6.66679V95.3335C0 95.7016 0.298478 96.0001 0.666668 96.0001H63.3333C63.7015 96.0001 64 95.7017 64 95.3335Z" fill="var(--CFD-theme-System-SecondaryContainer)"/>
            <path d="M46 11.3335V6.66679C46 6.2986 45.7015 6.00012 45.3333 6.00012H42.6667C42.2985 6.00012 42 5.70165 42 5.33346V0.666789C42 0.298599 41.7015 0.00012207 41.3333 0.00012207H22.6667C22.2985 0.00012207 22 0.298599 22 0.666789V5.33346C22 5.70165 21.7015 6.00012 21.3333 6.00012H18.6667C18.2985 6.00012 18 6.2986 18 6.66679V11.3335C18 11.7016 18.2985 12.0001 18.6667 12.0001H45.3333C45.7015 12.0001 46 11.7016 46 11.3335Z" fill="var(--CFD-theme-System-SecondaryContainer)"/>
          </svg>
          <p className={ style.noDataText }>{t(mainText)}</p>
          <p className={ style.noFoundText }>{t(subText)}</p>
        </StyledTableCell>
      </TableRow>
    </TableBody>
  )
}
