import React, { type FC } from 'react';
import { type IOrder } from '../../../../../redux/reducers/orders';
import { type IColumn } from '../../../../../interfaces/ColumnInterface';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ProgressComponent } from '../../../../../components';
import EmptyTableIcon from '../../../../../assets/images/emptyTable.svg';
import { styled } from '@mui/material/styles';
import styles from './DataTable.module.css';
import { useTranslation } from 'react-i18next';

const CustomTableHead = styled(TableHead)(() => ({
  height: '32px',
  backgroundColor: 'var(--CFD-theme-System-TableHead-Variant)',

  ' .MuiTableCell-root': {
    backgroundColor: 'var(--CFD-theme-System-TableHead-Variant)',
    padding: '4px 8px',
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontFamily: 'Arial',
    border: 'unset'
  }
}))

const CustomTableBody = styled(TableBody)(() => ({
  ' .MuiTableRow-root': {
    backgroundColor: 'var(--CFD-theme-Surface-2)',

    '&:hover': {
      backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_08)'
    },

    ' .MuiTableCell-root': {
      padding: '12px 8px',
      borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)',
      color: 'var(--CFD-theme-System-OnSurface)',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      ' .MuiButtonBase-root': {
        fontSize: '14px',

        '&:first-of-type': {
          marginRight: '8px'
        }
      }
    }
  }
}));

interface IDataTableProps {
  orders?: IOrder[]
  columns?: IColumn[]
  isLoading?: boolean
  tableSelected: number
}

export const DataTable: FC<IDataTableProps> = ({ orders = [], columns = [], isLoading = false, tableSelected }) => {
  const { t } = useTranslation();
  return (
    (orders.length > 0)
      ? (
          <Table sx={{ minWidth: 650 }} stickyHeader >
              <CustomTableHead>
              <TableRow>
                {
                  columns.map((column) => (
                    <TableCell key={`column-${column.name}`}>{column.name}</TableCell>
                  ))
                }
              </TableRow>
              </CustomTableHead>
              <CustomTableBody>
                {
                  isLoading
                    ? (
                      <tr className={styles.loaderContainer}>
                        <th>
                          <ProgressComponent type='circular' />
                        </th>
                      </tr>
                      )
                    : (
                    <>
                      {
                        orders.map((order, index) => (
                          <TableRow
                          key={`row-${index}`}
                          >
                            {
                              columns.map((column, idx) => (
                                <TableCell sx={column.sx ?? {}} key={`cell-${column.name}-${idx}`}>{column.cell(order, index)}</TableCell>
                              ))
                            }
                          </TableRow>
                        ))
                      }
                    </>
                      )
                }

              </CustomTableBody>
          </Table>
        )
      : (<div className={styles.emptyContainer}>
          <img src={EmptyTableIcon} alt="no_data" />
          <span>{t(tableSelected === 0 ? 'orders.no_active_orders' : 'orders.no_history_orders')}</span>
      </div>)
  )
}
