import React, { useState, useEffect, useMemo, createRef } from 'react';
import styles from '../Auth.module.css';
import compStyles from './Registration.module.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header, Wrapper, Container } from '../components';
import { ButtonComponent, StepperComponent, useSnackbar, type HandleOpenInterface } from '../../../components';
import { PersonalInfoStep, AccountDetailsStep, type IPersonalInfoRef, type IAccountDetailsRef } from './components';
import { registerUser } from '../../../redux/reducers/user';
import { getCountries, type ICountry } from '../../../redux/reducers/countries';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { type OptionInterface, type PayloadInterface } from '../../../interfaces';
import { Step, StepLabel, type StepIconProps } from '@mui/material';
import { getCountryCode, handleError } from './helper';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { AuthData } from '../../../auth/AuthWrapper';
import axios from 'axios';

const CustomStepIconRoot = styled('div')<{ completed?: boolean, active?: boolean }>(({ active, completed }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  backgroundColor: `${active === true || completed === true ? 'var(--CFD-theme-System-Tertiary)' : 'var(--CFD-theme-System-OnSurfaceVariant)'}`,
  opacity: `${active === true || completed === true ? '1' : '0.38'}`
}));

const CustomEditIcon = styled(EditIcon)({
  width: '16px !important',
  minWidth: '16px !important',
  height: '16px !important',
  minHeight: '16px !important',
  color: 'var(--CFD-theme-System-OnTertiary) !important',
  cursor: 'pointer'
});

export const Registration: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleOpen } = useSnackbar();
  const { loginByToken } = AuthData();

  const countries = useAppSelector(({ countries }) => countries);

  const personalInfoRef = createRef<IPersonalInfoRef>();
  const accountDetailRef = createRef<IAccountDetailsRef>();

  const SETTED_STEP = localStorage.getItem('step') ?? 0;
  const SETTED_REGISTRATION_DATA = localStorage.getItem('registration-data') ?? undefined;
  const IS_RESEND_BTN_ENABLED = localStorage.getItem('resend-btn-enabled') ?? undefined;
  const isResendBtnDefault = IS_RESEND_BTN_ENABLED !== undefined ? JSON.parse(IS_RESEND_BTN_ENABLED) : false;

  const [activeStep, setActiveStep] = useState(Number(SETTED_STEP));
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [birth, setBirth] = useState<string | null>(null);
  const [country, setCountry] = useState<OptionInterface | null | string>(null);
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState({ value: '', showPassword: false });
  const [confirmPassword, setConfirmPassword] = useState({ value: '', showPassword: false });
  const [referral, setReferral] = useState<string>('');
  const [isResendMailEnable, setIsResendMailEnable] = useState<boolean>(isResendBtnDefault as boolean);
  const [isRegisterLoading, setIsRegisterLoading] = useState<boolean>(false);

  const options: OptionInterface[] = useMemo((): OptionInterface[] => {
    return countries.slice().sort((a, b) => a.nicename.localeCompare(b.nicename)).map(({ iso, nicename }) => ({ label: nicename, value: iso }));
  }, [countries]);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      const { payload } = await dispatch(getCountries());
      const data = SETTED_REGISTRATION_DATA && JSON.parse(SETTED_REGISTRATION_DATA);

      if (data) {
        setFirstName(data.firstName as string);
        setLastName(data.lastName as string);
        setCountry(data.countryCode as string);
        setPhone(data.phone as string);
        setEmail(data.email as string);
        setPassword({ value: data.password, showPassword: false });
        setConfirmPassword({ value: data.confirmPassword, showPassword: false });
        setBirth(data.dateOfBirth as string);
        setReferral(data.referralCode as string);
      }

      const fetchCountryByIP = async (): Promise<void> => {
        try {
          const response = await axios.get('https://ipapi.co/json/');
          const countryCode = response.data.country_code as string;
          const optionByCountryCode = payload?.find((country: ICountry) => country.iso === countryCode);
          optionByCountryCode && setCountry({ label: optionByCountryCode.nicename, value: optionByCountryCode.iso });
        } catch (error) {
          console.error('Error fetching country by IP:', error);
        }
      };

      !data?.countryCode && await fetchCountryByIP();
    };

    initialize();
  }, [dispatch]);

  const submit = async (): Promise<void> => {
    setIsRegisterLoading(true);
    const data = {
      firstName,
      lastName,
      phone,
      email,
      password: password.value,
      confirmPassword: confirmPassword.value,
      referralCode: referral,
      dateOfBirth: birth !== null ? new Date(birth) : null,
      countryCode: getCountryCode(country),
      hostname: window.location.host,
      clientType: 1,
      isSelfRegistered: true
    }

    const response = await dispatch(registerUser(data));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      const openErrorParams: HandleOpenInterface = {
        message: handleError(payload),
        actionText: '',
        severity: 'error',
        autoClose: false
      }

      handleOpen(openErrorParams);
      setIsRegisterLoading(false);

      return;
    }

    if (payload.data.token != null) {
      loginByToken(payload.data.token as string);

      return;
    }

    const openSuccessParams: HandleOpenInterface = {
      message: payload.data.message,
      actionText: '',
      autoClose: false
    }

    setIsResendMailEnable(true);

    handleOpen(openSuccessParams);
    setIsRegisterLoading(false);
    localStorage.setItem('registration-data', JSON.stringify(data));
    localStorage.setItem('resend-btn-enabled', 'true');
    localStorage.setItem('step', '1');
  }

  const Subtitle = (): JSX.Element => (
    <>
        <h2>{t('login_page.sign_up')}</h2>
        <div className={styles.containerHeaderActions__row}>
            <span>{t('login_page.have_acc')}</span>
            <ButtonComponent
                variant='outlined'
                onClick={() => { navigate('/login'); }}
            >
                {t('login_page.sign_in')}
            </ButtonComponent>
        </div>
    </>
  );

  const CustomStepIcon = (props: StepIconProps): JSX.Element => {
    const { active, completed, className } = props;

    return (
      <CustomStepIconRoot completed={completed} active={active} className={className}>
        {
        (completed === true)
          ? <CustomEditIcon />
          : ((active === true)
              ? <div className={compStyles.customStepNumberIconActive}>{props.icon}</div>
              : <div className={compStyles.customStepNumberIconActive}>{props.icon}</div>)
        }
      </CustomStepIconRoot>
    );
  }

  const changeStep = (stepNumber: number): void => {
    if (stepNumber === 0) {
      setActiveStep(0);

      return;
    }

    const prevStep = stepNumber - 1;
    const refs = [personalInfoRef]
    const prevRef = refs[prevStep];

    if (prevRef?.current?.isStepFinished() === true) {
      setActiveStep(stepNumber);
    }
  }

  return (
    <Wrapper>
        <Header />
        <Container
          subtitle={<Subtitle />}
          content={
            <div className={compStyles.steperWrapper}>
              <StepperComponent activeStep={activeStep}>
                <Step>
                  <StepLabel StepIconComponent={CustomStepIcon} onClick={() => { changeStep(0); }}>{t('login_page.personal_info')}</StepLabel>
                  <PersonalInfoStep
                    firstName={firstName}
                    lastName={lastName}
                    country={country}
                    options={options}
                    birth={birth}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    setCountry={setCountry}
                    setBirth={setBirth}
                    nextStep={changeStep}
                    ref={personalInfoRef}
                  />
                </Step>
                <Step>
                  <StepLabel StepIconComponent={CustomStepIcon} onClick={() => { changeStep(1); }}>{t('login_page.account_details')}</StepLabel>
                  <AccountDetailsStep
                    phone={phone}
                    email={email}
                    referral={referral}
                    password={password}
                    confirmPassword={confirmPassword}
                    setPhone={setPhone}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    setConfirmPassword={setConfirmPassword}
                    nextStep={changeStep}
                    setReferral={setReferral}
                    ref={accountDetailRef}
                    isResendBtnEnabled={isResendMailEnable}
                    isRegisterLoading={isRegisterLoading}
                    submit={submit}
                  />
                </Step>
                {/* <Step>
                  <StepLabel StepIconComponent={CustomStepIcon} onClick={() => { changeStep(2); }}>{t('login_page.user_details')}</StepLabel>
                  <UserDetailsStep
                    email={email}
                    referral={referral}
                    isResendBtnEnabled={isResendMailEnable}
                    isRegisterLoading={isRegisterLoading}
                    setReferral={setReferral}
                    submit={submit}
                  />
                </Step> */}
              </StepperComponent>
            </div>
          }
        />
    </Wrapper>
  )
}
