import 'dayjs/locale/ar';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';
import 'dayjs/locale/pl';
import 'dayjs/locale/ru';
import 'dayjs/locale/es';
import 'dayjs/locale/tr';

const dayjsToLocaleMap: Record<string, string> = {
  ar: 'ar',
  de: 'de',
  en: 'en',
  fr: 'fr',
  it: 'it',
  nld: 'nl',
  pl: 'pl',
  ru: 'ru',
  sp: 'es',
  tr: 'tr'
};

export { dayjsToLocaleMap };
