import React, { type FC } from 'react';
import { useAppDispatch } from '../../../hooks/redux';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { t } from 'i18next';

import { MobileHeaderLiveChatComponent } from './MobileHeaderLiveChatComponent/MobileHeaderLiveChatComponent';
import { setOpenByClick } from '../../../redux/reducers/chat';

import style from './HeaderLiveChatComponent.module.css';

type SetStateAction<T> = T | ((prevState: T) => T);

interface HeaderLiveChatComponentInterface {
  setShowBody: (el: SetStateAction<boolean>) => void
  setOpen: (el: boolean) => void
  setOpenDeposit: (el: boolean) => void
  setOpenMenu: (el: boolean) => void
  setOpenSelectAccounts: (el: boolean) => void
}

export const HeaderLiveChatComponent: FC<HeaderLiveChatComponentInterface> = ({
  setOpen,
  setShowBody,
  setOpenDeposit,
  setOpenMenu,
  setOpenSelectAccounts
}) => {
  const dispatch = useAppDispatch();
  const handleClose = (): void => {
    dispatch(setOpenByClick(false));
    setOpen(false);
  }

  return (
    <>
      <div className={ style.headerLiveChat }>
        <div className={ style.headerLiveChatTitleContainer }>
          <p className={ style.headerLiveChatTitle }>{t('liveChat.title')}</p>
        </div>
        <div className={ style.headerOptions }>
          <button className={ style.headerBtnContainer } onClick={() => { setShowBody((prevState) => !(prevState)) }}>
            <RemoveOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
          </button>
          <button className={ style.headerBtnContainer } onClick={handleClose}>
            <CloseOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
          </button>
        </div>
      </div>
      <MobileHeaderLiveChatComponent
        setChatOpen={ setOpen }
        setOpenDeposit={ setOpenDeposit }
        setOpenMenu={setOpenMenu}
        setOpenSelectAccounts={setOpenSelectAccounts}
      />
    </>
  )
}
