import React, { type FC, useContext } from 'react';
import { DialogContext } from '../context/DialogContextProvider';
import { ButtonComponent } from '../../../components';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import styles from './modals.module.css';
import { useAppSelector } from '../../../hooks/redux';
import { useTranslation } from 'react-i18next';

interface IContentProps {
  position: number
  tradeType: number
  symbol: string
}

interface IFooterProps {
  onClick: () => void
}

export const StopModalContent: FC<IContentProps> = ({ position, tradeType, symbol }): JSX.Element => {
  const { t } = useTranslation();
  const tradeTypeText = t(tradeType === 1 ? 'labels.buy' : 'labels.sell');
  return (
      <div className={styles.stopDialogContent}>
          <div className={styles.iconRow}>
            <WarningAmberIcon sx={{ color: 'var(--CFD-theme-System-Secondary)' }}/>
          </div>
          <div className={styles.textColumn}>
            <h2>{t('labels.want_to_close_question')}</h2>
              <span>{t('labels.position_info', { tradeType: tradeTypeText, position, symbol })}</span>
          </div>
      </div>
  );
}

export const StopModalFooter: FC<IFooterProps> = ({ onClick }): JSX.Element => {
  const { hideDialog } = useContext(DialogContext);
  const { t } = useTranslation();
  const isModalLoading = useAppSelector(state => state.modal.isLoading);
  return (
        <div className={styles.dialogFooter}>
            <ButtonComponent btnstyle='primary' variant="text" onClick={hideDialog}>{t('accounts_page.cancel')}</ButtonComponent>
            <ButtonComponent btnstyle='primary' variant="text" loading={isModalLoading} onClick={() => { onClick(); }}>{t('accounts_page.confirm')}</ButtonComponent>
        </div>
  );
}
