import React, { type FC, useMemo } from 'react';
import { t } from 'i18next';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import { ButtonComponent, ProgressComponent } from '../../../../components';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';
import { selectUserDocument, selectUserDocumentDownloadStatus } from '../../../../redux/selectors/document';
import { setShowDocumentComponent } from '../../../../redux/reducers/showDocumentComponent';

import style from './TermsAndConditions.module.css';

interface ITermsAndConditions {
  downloadDocumentHandler: () => void
  alreadySignedDoc: boolean
}

export const TermsAndConditions: FC<ITermsAndConditions> = ({ downloadDocumentHandler, alreadySignedDoc }) => {
  const dispatch = useAppDispatch();
  const document = useAppSelector(selectUserDocument);
  const downloadStatus = useAppSelector(selectUserDocumentDownloadStatus);

  const btnHandler = (): void => {
    dispatch(setShowDocumentComponent(true));
  }

  const isSignedText = ((document?.isSigned) ?? false) ? t('labels.signed') : t('labels.not_signed');
  const backgroundLabelColor = ((document?.isSigned) ?? false) ? 'var(--CFD-theme-State-layers-on-tertiary-opacity_16)' : 'var(--CFD-theme-State-layers-outline-opacity_08)';
  const textColorLabel = ((document?.isSigned) ?? false) ? 'var(--CFD-theme-System-Tertiary)' : 'var(--CFD-theme-System-Outline)';

  const documentSaveBtn = useMemo(() => {
    return downloadStatus
      ? (
          <div className={style.loadingContainer}>
            <ProgressComponent type="circular" sx={{ width: '28px !important', height: '28px !important' }} />
          </div>
        )
      : (
          <button className={style.btn} onClick={downloadDocumentHandler}>
            <SaveAltIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
          </button>
        );
  }, [downloadStatus, downloadDocumentHandler]);

  return (
    <div className={style.wrapper}>
      <div className={style.termsContainer}>
        <div className={style.termsOptions}>
          <p className={style.termsOptionsTitle}>
            {
              ((document?.isSigned ?? false) || alreadySignedDoc)
                ? `${t('labels.a_copy_of_the_document_has_been_signed')}`
                : `${t('labels.complete_your_profile_by_submitting_the_signed_agreement')}`
            }
          </p>
          <div className={style.termsOptionsBtnContainer}>
            {(document?.isSigned ?? false)
              ? documentSaveBtn
              : (<ButtonComponent customInlineStyles={{ minHeight: '32px' }} variant='outlined' onClick={btnHandler} disabled={alreadySignedDoc}>
                  {t('labels.sign_the_document')}
                </ButtonComponent>)
            }
          </div>
        </div>
        <div className={style.termsStatusContainer}>
          <p className={style.termsStatusTitle}>
            {t('labels.document_status')}
          </p>
          <div
            className={style.statusLabelContainer}
            style={{ backgroundColor: backgroundLabelColor }}
          >
            <p
              className={style.statusLabel}
              style={{ color: textColorLabel }}
            >{isSignedText}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
