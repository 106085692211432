import React, { type FC, useEffect, createRef } from 'react';
import { useAppSelector } from '../../../../../../hooks/redux';
import { type IBasicDataFeed, type TradingTerminalFeatureset, type ResolutionString, type LanguageCode, type ThemeName, type LibrarySymbolInfo } from '@wisecryptoanalysis/charting_library';
import { useTheme } from '../../../../../../components';
import { widget as Widget } from '@wisecryptoanalysis/charting_library';
import DataFeed from './datafeed/datafeed';
import styles from './TVChart.module.css';
import { useTranslation } from 'react-i18next';
import { getLanguageCode } from '../../../../../../i18n';

interface ITVChartProps {
  asset: string
  uniqueId: number
  brokerSymbol: string
  isOpened: boolean
}

export interface TradingTerminalWidgetOptions extends LibrarySymbolInfo {
  symbol: string
  interval: ResolutionString
  container: string | HTMLElement
  library_path: string
  uniqueId: number
  locale: LanguageCode
  disabled_features: TradingTerminalFeatureset[]
  enabled_features: TradingTerminalFeatureset[]
  theme: ThemeName
  toolbar_bg: string
  overrides: object
  show_popup_button: boolean
  autosize: boolean
  brokerSymbol: string
  isOpened: boolean
  userId: string | undefined
  widgetType: string
}

export const TVChart: FC<ITVChartProps> = ({ asset, uniqueId, isOpened, brokerSymbol }) => {
  const chartContainerRef = createRef<HTMLDivElement>();
  const { theme } = useTheme();
  const { i18n } = useTranslation();
  const { _id: userId } = useAppSelector((state) => state.user);

  const defaultProps = {
    libraryPath: '/lib/chart/',
    symbol: `${process.env.REACT_APP_BRAND_NAME}:${asset}`,
    interval: '5' as ResolutionString,
    show_popup_button: false,
    autosize: true,
    fullscreen: true,
    chartsStorageUrl: 'https://saveload.tradingview.com',
    container: 'TVChartContainer',
    chartsStorageApiVersion: '0.01',
    locale: getLanguageCode(i18n.language),
    userId,
    clientId: `${process.env.REACT_APP_BRAND_NAME}`,
    uniqueId,
    isOpened,
    brokerSymbol
  };

  const getColorByTheme = (theme: string): string => (theme === 'dark' ? '#1B2033' : '#E9EBF5');

  useEffect(() => {
    if (chartContainerRef === null || userId === undefined) return;

    const widgetOptions: TradingTerminalWidgetOptions = {
      symbol: defaultProps.symbol,
      full_name: defaultProps.symbol,
      name: defaultProps.symbol,
      description: `${defaultProps.symbol} ${defaultProps.interval}`,
      type: 'cfd',
      timezone: 'Etc/UTC',
      format: 'price',
      pricescale: 1000,
      // BEWARE: no trailing slash is expected in feed URL
      // datafeed: defaultProps.datafeed,
      interval: defaultProps.interval,
      container: chartContainerRef.current ?? 'container',
      library_path: defaultProps.libraryPath,
      uniqueId: defaultProps.uniqueId,
      locale: getLanguageCode(i18n.language),
      disabled_features: ['use_localstorage_for_settings', 'header_symbol_search', 'symbol_search_hot_key', 'header_saveload', 'header_compare'],
      enabled_features: ['control_bar', 'timeframes_toolbar', 'header_in_fullscreen_mode'],
      theme: theme as ThemeName ?? 'dark',
      toolbar_bg: getColorByTheme(theme),
      overrides: {
        'paneProperties.background': getColorByTheme(theme),
        'paneProperties.backgroundType': 'solid'
      },
      // charts_storage_url: defaultProps.chartsStorageUrl,
      // charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      // client_id: defaultProps.clientId,
      userId: defaultProps.userId,
      // fullscreen: defaultProps.fullscreen,
      // studies_overrides: defaultProps.studiesOverrides,
      widgetType: 'widget',
      show_popup_button: defaultProps.show_popup_button,
      autosize: defaultProps.autosize,
      isOpened: defaultProps.isOpened,
      brokerSymbol: defaultProps.brokerSymbol,
      session: '',
      exchange: '',
      listed_exchange: '',
      minmov: 0,
      supported_resolutions: ['1', '5', '15', '30', '1H', '2H', '4H', '6H', '1D', '1W', '1M'] as ResolutionString[]
    };

    const tvWidget = new Widget({
      ...widgetOptions,
      datafeed: new DataFeed(widgetOptions) as IBasicDataFeed
    });

    return () => {
      tvWidget.remove();
    };
  }, [chartContainerRef, theme, userId, i18n.language]);

  return (
    <div
      ref={chartContainerRef}
      className={styles.tvChart}
    />
  )
}
