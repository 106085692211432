import React, { type FC } from 'react'
import { MuiTelInput, type MuiTelInputInfo } from 'mui-tel-input';
import { styled } from '@mui/material/styles';
import './style.css';

const CustomizedInput = styled(MuiTelInput)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    width: '100%',
    paddingLeft: '16px',
    height: '40px',
    borderRadius: '4px',
    border: '1px solid var(--CFD-theme-System-Outline)',
    outline: 'unset',
    '&.Mui-focused': {
      border: '3px solid var(--CFD-theme-System-Primary)'
    },
    '&.Mui-disabled': {
      border: '1px solid var(--CFD-theme-System-Outline)',
      opacity: '0.3'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)'
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: 0,
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left'
  },
  '& .MuiInputBase-input.Mui-disabled': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
    WebkitTextFillColor: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
    opacity: '0.3'
  }
}));

const MenuProps = {
  PaperProps: {
    className: 'PhoneMenu',
    style: {
      backgroundColor: 'var(--CFD-theme-Surface-3)',
      color: 'var(--CFD-theme-System-OnSurface)',
      fontFamily: 'Arial',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      borderRadius: '4px',
      padding: 0
    }
  }
};

interface InputInterface {
  onChange?: (value: string, info: MuiTelInputInfo) => void
  value?: string
  id?: string
  error?: boolean
  errorText?: string
  disabled?: boolean
}

export const PhoneInputComponent: FC<InputInterface> = ({
  onChange = () => {},
  id = '',
  value = '',
  error,
  errorText,
  disabled = false
}) => {
  return (
    <CustomizedInput
     MenuProps={MenuProps}
     onChange={onChange}
     defaultCountry='US'
     value={value}
     id={id}
     error={error}
     helperText={errorText}
     disabled={disabled}
     sx={{
       '& input': {
         backgroundColor: 'transparent',
         '&:-webkit-autofill': {
           WebkitBoxShadow: '0 0 0 1000px inherit inset',
           WebkitTextFillColor: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
           WebkitBackgroundClip: 'text'
         }
       },
       '.Mui-error': {
         color: 'var(--CFD-theme-System-Error) !important'
       }
     }}
    />
  )
}
