import React, { type SetStateAction, type Dispatch, forwardRef, useMemo, useImperativeHandle, type RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../hooks/redux';
import { resendToken } from '../../../../redux/reducers/user';
import { type PayloadInterface } from '../../../../interfaces';
import styles from '../../Auth.module.css';
import { StepContent } from '@mui/material';
import { matchIsValidTel } from 'mui-tel-input';
import { PhoneInputComponent, InputComponent, PasswordInputComponent, ButtonComponent, type InterfacePasswordValue, CheckboxComponent, useSnackbar, type HandleOpenInterface } from '../../../../components';
import { handleError } from '../helper';

interface IAccountDetailsStep {
  phone: string
  email: string
  referral: string
  password: InterfacePasswordValue
  confirmPassword: InterfacePasswordValue
  isResendBtnEnabled: boolean
  isRegisterLoading: boolean
  setReferral: Dispatch<SetStateAction<string>>
  setPhone: Dispatch<SetStateAction<string>>
  setEmail: Dispatch<SetStateAction<string>>
  setPassword: Dispatch<SetStateAction<InterfacePasswordValue>>
  setConfirmPassword: Dispatch<SetStateAction<InterfacePasswordValue>>
  submit: () => Promise<void>
  nextStep: (stepNumber: number) => void
  ref: RefObject<IAccountDetailsRef>
}

export interface IAccountDetailsRef {
  isStepFinished: () => boolean
}

const AccountDetailsStep = forwardRef<IAccountDetailsRef, IAccountDetailsStep>(({
  phone,
  email,
  password,
  referral,
  confirmPassword,
  isResendBtnEnabled,
  isRegisterLoading,
  setReferral,
  submit,
  setPhone,
  setEmail,
  setPassword,
  setConfirmPassword,
  nextStep
}, ref): JSX.Element => {
  const { t } = useTranslation();
  const { handleOpen } = useSnackbar();
  const dispatch = useAppDispatch();

  const [phoneValidation, setPhoneValidation] = useState({
    isError: false,
    message: ''
  });
  const [emailValidation, setEmailValidation] = useState({
    isError: false,
    message: ''
  });

  const [isUserHaveReffCode, setIsUserHaveReffCode] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState<boolean>(false);

  const emailRegExpValidation = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/;

  const isStepFinished: boolean = useMemo((): boolean => {
    return (
      email.length > 0 &&
        !emailValidation.isError &&
        password.value.length > 0 &&
        confirmPassword.value.length > 0 &&
        phone.length > 0 &&
        !phoneValidation.isError &&
        confirmPassword.value === password.value
    )
  }, [email, password, confirmPassword, phone])

  useImperativeHandle(ref, () => ({
    isStepFinished (): boolean {
      return isStepFinished
    }
  }));

  const handleResendToken = async (): Promise<void> => {
    setIsResendLoading(true);
    const response = await dispatch(resendToken({ email }));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      const openErrorParams: HandleOpenInterface = {
        message: handleError(payload),
        actionText: '',
        severity: 'error',
        autoClose: false
      }

      handleOpen(openErrorParams);
      setIsResendLoading(false);

      return;
    }

    const openSuccessParams: HandleOpenInterface = {
      message: payload.data,
      actionText: '',
      autoClose: false
    }

    handleOpen(openSuccessParams);
    setIsResendLoading(false);
  }

  const setPhoneNumber = (value: string): void => {
    const phoneValidationError = !matchIsValidTel(value);

    setPhoneValidation({ isError: phoneValidationError, message: phoneValidationError ? t('errors.phone_pattern_incorrect') : '' });

    setPhone(value);
  }

  const setEmailField = (value: string): void => {
    const emailValidationError = value.match(emailRegExpValidation) === null;

    setEmailValidation({ isError: emailValidationError, message: emailValidationError ? t('errors.email_pattern_incorrect') : '' });
    setEmail(value)
  }

  return (
    <StepContent>
      <div className={styles.containerBody__column}>
        <div className={styles.containerBody}>
          <label htmlFor="phone">{t('login_page.phone_number_field')}</label>
          <div className={styles.inputContainer}>
            <PhoneInputComponent
              id="phone"
              onChange={setPhoneNumber}
              value={phone}
              error={phoneValidation.isError}
              errorText={phoneValidation.message}
             />
          </div>
          <div className={ styles.emptyBox }></div>
        </div>
        <div className={styles.containerBody}>
          <label htmlFor="email">{t('login_page.email_field')}</label>
          <div className={styles.inputContainer}>
            <InputComponent
              id="email"
              value={email}
              setValue={setEmailField}
              error={emailValidation.isError}
              errorText={emailValidation.message}
             />
          </div>
          <div className={ styles.emptyBox }></div>
        </div>
        <div className={styles.containerBody}>
          <label htmlFor="password">{t('login_page.password_field')}</label>
          <div className={styles.inputContainer}>
            <PasswordInputComponent id="password" value={password} setValue={setPassword} />
          </div>
          <div className={ styles.emptyBox }></div>
        </div>
        <div className={styles.containerBody}>
          <label htmlFor="confirmPassword">{t('login_page.confirm_password_field')}</label>
          <div className={styles.inputContainer}>
            <PasswordInputComponent id="confirmPassword" value={confirmPassword} setValue={setConfirmPassword} />
          </div>
          <div className={ styles.emptyBox }></div>
        </div>
        {
          isUserHaveReffCode && (
            <div className={styles.containerBody}>
            <label htmlFor="reffCode">{t('login_page.ref_code_field')}</label>
            <div className={styles.inputContainer}>
              <InputComponent id="reffCode" value={referral} setValue={setReferral} />
            </div>
            <div className={ styles.emptyBox }></div>
          </div>
          )
        }
        <div className={styles.containerBody}>
          <label/>
          <div className={styles.inputContainer}>
            <CheckboxComponent id="reffCodeBox" checked={isUserHaveReffCode} onChange={({ target: { checked } }) => { setIsUserHaveReffCode(checked); }}/>
            <label htmlFor="reffCodeBox">{t('login_page.i_have_ref')}</label>
          </div>
          <div className={ styles.emptyBox }></div>
        </div>
        <div className={styles.containerBodyBtn}>
          <div className={ styles.emptyBoxLeft }></div>
          <div className={ styles.containerBtn }>
            <ButtonComponent
              variant='contained'
              disabled={!isStepFinished}
              loading={isRegisterLoading}
              onClick={() => { submit(); }}
            >{t('login_page.register')}</ButtonComponent>
          </div>
          <div className={ styles.emptyBox }></div>
        </div>
        {
          isResendBtnEnabled && (
            <div className={styles.containerBodyBtn}>
              <div className={ styles.emptyBoxLeft }></div>
              <div className={ styles.containerBtn }>
                <ButtonComponent
                  variant='text'
                  loading={isResendLoading}
                  onClick={() => { handleResendToken(); }}
                >
                  {t('login_page.resend')}
                </ButtonComponent>
              </div>
              <div className={ styles.emptyBox }></div>
            </div>
          )
        }
      </div>
    </StepContent>
  )
});

AccountDetailsStep.displayName = 'AccountDetailsStep';

export { AccountDetailsStep };
