import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';

export const CustomPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  backgroundColor: 'var(--CFD-theme-Surface-3)',
  borderRadius: '4px',
  display: 'flex',
  width: '140px',
  height: '100%',
  maxHeight: '240px',
  minWidth: '112px',
  maxWidth: '360px',
  padding: '8px 0px',
  alignItems: 'flex-start',
  overflow: 'hidden'
}));

export const StyledTypography = styled(Typography)<{ error: boolean }>(({ error }) => ({
  color: error ? 'var(--CFD-theme-System-Error)' : 'var(--CFD-theme-System-Outline)',
  fontFamily: 'Arial',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.4px',
  marginTop: '4px',
  leadingTrim: 'both',
  textEdge: 'cap'
}));
