import React, { type FC, useState } from 'react';
import { Autocomplete, FormControl, TextField, Typography } from '@mui/material';
import { CustomPaper, StyledTypography } from '../StyledComponents';
import { t } from 'i18next';

interface Option { label: string, value: string }

interface DateSelectProps {
  value: string
  onChange: (value: string) => void
  options: Option[]
  label: string
  defaultLabel: string
  error?: boolean
  onLastSelectedValueChange: (value: string | null) => void
}

const NoOptionsText = (): React.JSX.Element => (
    <Typography style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}>
        {t('labels.no_options')}
    </Typography>
);

export const DateSelect: FC<DateSelectProps> = ({ value, onChange, options, label, defaultLabel, error = false, onLastSelectedValueChange }) => {
  const [lastSelectedValue, setLastSelectedValue] = useState(options.find(o => o.value === value) ?? null);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event: any, newValue: Option | null): void => {
    onLastSelectedValueChange(newValue?.value ?? null);
    if (newValue) {
      onChange(newValue.value);
      setLastSelectedValue(newValue);
    }
  };

  const handleInputChange = (event: any, newInputValue: string): void => {
    setInputValue(newInputValue);
  };

  const handleBlur = (): void => {
    if (inputValue === '') {
      setLastSelectedValue(null);
    }
  };

  return (
        <FormControl style={{ width: '100%', height: '40px' }}>
            <Autocomplete
                value={lastSelectedValue ?? undefined}
                onChange={handleChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onBlur={handleBlur}
                options={options}
                noOptionsText={<NoOptionsText />}
                getOptionLabel={(option) => option.value.padStart(2, '0')}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                PaperComponent={CustomPaper}
                slotProps={{
                  paper: {
                    sx: {
                      '& .MuiAutocomplete-listbox': {
                        width: '100%',
                        height: '100%',
                        maxHeight: '240px',
                        overflowY: 'auto',
                        '& .MuiAutocomplete-option': {
                          width: '100%'
                        }
                      }
                    }
                  }
                }}
                sx={{
                  '& .MuiAutocomplete-inputRoot': {
                    padding: '0 !important',
                    height: '40px'
                  },
                  '& .MuiAutocomplete-noOptions': {
                    color: 'red !important',
                    fontWeight: 'bold'
                  }
                }}
                renderOption={(props, option) => (
                    <li {...props} style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}>
                        {option.label.padStart(2, '0')}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={defaultLabel}
                        variant="outlined"
                        error={error}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: error ? 'var(--CFD-theme-System-Error) !important' : 'var(--CFD-theme-System-Outline)'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: error ? 'var(--CFD-theme-System-Error)' : 'var(--CFD-theme-System-Outline)'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'var(--CFD-theme-System-Primary)'
                            },
                            '& .MuiInputBase-input': {
                              color: 'var(--CFD-theme-System-OnSurfaceVariant)',
                              fontFamily: 'Arial',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '24px',
                              letterSpacing: '0.5px',
                              textAlign: 'center',
                              padding: '8px 0 !important'
                            },
                            '& .MuiAutocomplete-endAdornment': {
                              display: 'none'
                            }
                          }
                        }}
                    />
                )}
            />
            <StyledTypography error={error}>{label}</StyledTypography>
        </FormControl>
  );
};
