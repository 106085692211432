import React, { useEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { type SavingInvestment } from '../../../../../interfaces';
import { SectionSuccessModal, TimelineProgressComponent } from '../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { getCurrencyRates } from '../../../../../redux/reducers/currencyRates';
import { calcSavingFlexibleEarnings, calculateSavingFlexibleLockPeriod, formatDate } from '../../../../../helpers/util';

import style from './Step2SectionModalRedeem.module.css';

interface InterfaceStep2SectionModalRedeem {
  selectedInvestment: SavingInvestment | null
  currency: string
  isFlexible: boolean | null
}

const getCurrentRate = (investment: SavingInvestment, rates?: Record<string, number>, accountSymbol?: string): number => {
  if (investment?.currencyId && investment?.currency) {
    return rates?.[investment.currency.symbol] ?? 0;
  }
  return investment?.customCurrency?.rates?.[accountSymbol ?? ''] ?? 0;
}

const getCurrentSymbol = (investment: SavingInvestment): string => {
  if (investment?.currencyId && investment?.currency) {
    return investment.currency.symbol;
  }
  return investment?.customCurrency?.name ?? '';
}

export const Step2SectionModalRedeem: FC<InterfaceStep2SectionModalRedeem> = ({ selectedInvestment, currency, isFlexible }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const accounts = useAppSelector((state) => state.accounts);
  const selectedAccount = accounts.find((item) => item._id === currency);
  const { rates } = useAppSelector(state => state.currencyRates);

  useEffect(() => {
    if (selectedAccount !== undefined && selectedAccount !== null) {
      dispatch(getCurrencyRates(selectedAccount.cfdAccountCurrency.symbol))
    }
  }, [selectedAccount])

  if (selectedInvestment !== undefined && selectedInvestment !== null) {
    const lockedPeriodInDays = calculateSavingFlexibleLockPeriod(selectedInvestment.createdAt);
    const earnings = calcSavingFlexibleEarnings(selectedInvestment.investedAmount, selectedInvestment.pct, lockedPeriodInDays, 2);

    return (
      <section className={ style.sectionWrapper }>
        <SectionSuccessModal
          title={t('labels.redeem_successful')}
        />
        <div className={ style.redemptionAmountContainer }>
          <p className={ style.detailsText1 }>{ `${t('labels.redemption_amount')}:` }</p>
          <p className={ style.detailsText }>
            {`${(isFlexible ?? false) ? earnings : selectedInvestment.finalEarnings} ${getCurrentSymbol(selectedInvestment)}`}
          </p>
        </div>
        <div className={ style.detailsWrapper }>
          <div className={ style.details }>
            <p className={ style.detailsText1 }>
              { `${t('savings_page.table_body.accounts_tab.accordion_details.button.redeem')} ${t('labels.to')}` }
            </p>
            <p className={ style.detailsText }>{selectedAccount?.cfdAccountCurrency.name}</p>
          </div>

          <div className={ style.details }>
            <p className={ style.detailsText1 }>
              { `${t('labels.transfer')} ${t('labels.amount')}` }
            </p>
            <p className={ style.detailsText }>
              {`${(isFlexible ?? false)
                ? earnings
                : (selectedInvestment.finalEarnings / getCurrentRate(selectedInvestment, rates, selectedAccount?.cfdAccountCurrency.name)).toFixed(3)} ${getCurrentSymbol(selectedInvestment)}`}
            </p>
          </div>
        </div>
        {
          !(isFlexible ?? false) &&
            <div className={ style.summaryContainer }>
              <TimelineProgressComponent
                heigth={ 40 }
                amountOfTimelines={ 1 }
                percentageOfFill={ 100 }
                paddingBetweenTimeline={ 20 }
                timeLines={
                  [
                    {
                      label: `${t('savings_page.table_body.accounts_tab.accordion_details.timeline_labels.created_date')}`,
                      value: formatDate(new Date(selectedInvestment.createdAt as string | Date))
                    },
                    {
                      label: `${t('savings_page.table_body.accounts_tab.accordion_details.timeline_labels.release_date')}`,
                      value: formatDate(new Date(selectedInvestment.releaseTime as string | Date))
                    }
                  ]
                }
              />
            </div>
        }
      </section>
    )
  }

  return <></>
}
