import React, { type FC } from 'react';
import { t } from 'i18next';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import style from './HeaderModalSign.module.css';

interface IHeaderModalSign {
  setOpen: () => void
  setStep: (el: number) => void
}

export const HeaderModalSign: FC<IHeaderModalSign> = ({ setOpen, setStep }) => {
  const handleBtnClick = (): void => {
    setOpen();
    setStep(1);
  }
  return (
    <header className={ style.dialogHeader }>
      <div className={ style.dialogHeaderContent }>
        <h3 className={ style.dialogHeaderTitle }>
          { t('labels.sign_your_agreement') }
        </h3>
      </div>
      <div className={ style.dialogHeaderBtnContainer }>
        <CloseOutlinedIcon onClick={ handleBtnClick } sx={{ color: 'var(--CFD-theme-System-OnSurface)', cursor: 'pointer' }}/>
      </div>
    </header>
  );
}
