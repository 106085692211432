import React, { type FC, useState, useEffect, useMemo } from 'react';

import { CardDepositComponent } from './CardDepositComponent/CardDepositComponent';
import { SuccessDepositFormComponent } from '../SuccessDepositFormComponent/SuccessDepositFormComponent';
import { getFiatCurrencies } from '../../../../redux/reducers/currencies';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { CardRadioOptionsComponent } from './CardRadioOptionsComponent/CardRadioOptionsComponent';
import { CardServiceDepositFormComponent } from '../CardServiceDepositFormComponent/CardServiceDepositFormComponent';
import { getPaymentServices } from '../../../../redux/reducers/paymentService';

import { useWindowSize } from '../../../../hooks/useWindowSize';
import { setShowFooter } from '../../../../redux/reducers/showFooter';
import { t } from 'i18next';
import { type OptionInterface } from '../../../../interfaces';

import style from './CardDepositFormComponent.module.css';

interface InterfaceCardDepositFormComponent {
  setOpen: (el: boolean) => void
  setStep: (el: number) => void
  step: number
  selectedAccount: IAccount | null
}

const CardsOptions: Array<{ ticker: string, isService: boolean }> = [
  { ticker: 'Card (Visa / Mastercard)', isService: false }
]

export const CardDepositFormComponent: FC<InterfaceCardDepositFormComponent> = ({ setOpen, setStep, step, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const [screenWidth] = useWindowSize();

  const currencies = useAppSelector((state) => state.currencies);
  const paymentServices = useAppSelector((state) => state.paymentServices);
  const { brandId } = useAppSelector((state) => state.user);
  const { showFooter } = useAppSelector((state) => state.showFooter);

  const [selectedCardOptions, setSelectedCardOptions] = useState<string>(CardsOptions[0].ticker);
  const [cardOptionsState, setCardOptionsState] = useState<Array<{ ticker: string, isService: boolean }>>(CardsOptions);
  const [currencySelect, setCurrencySelect] = useState<OptionInterface>({
    value: '',
    label: '',
    symbol: ''
  });
  const [amount, setAmount] = useState<string>('');

  const isMobile = screenWidth <= 599;

  const selectedCurrencySymbol = useMemo(() => (
    currencies.find(({ _id }) => _id === currencySelect.value)?.symbol ?? ''
  ), [currencySelect]);

  useEffect(() => {
    dispatch(getFiatCurrencies());
  }, []);

  useEffect(() => {
    if (brandId !== undefined) {
      dispatch(getPaymentServices({ brandId, type: 'fiat' }))
    }
  }, [brandId]);

  useEffect(() => {
    for (const service of paymentServices) {
      if (service.name !== undefined && service.name.length > 0) {
        const existing = cardOptionsState.find(({ ticker }) => ticker === service.name);

        if (existing === undefined) {
          const serviceName: string = service.name;
          setCardOptionsState((prev) => ([...prev, { ticker: serviceName, isService: true }]));
        }
      }
    }
  }, [paymentServices, cardOptionsState])

  const handleCloseDepositBtn = (): void => {
    if (isMobile && !showFooter) dispatch(setShowFooter(true));
    setOpen(false);
  }

  return (
    <>
      {
        (step === 1)
          ? <>
              <div className={ style.wrapper }>
                <div className={ style.radioCardsWrapper }>
                  <CardRadioOptionsComponent options={ cardOptionsState } selectedOptions={ selectedCardOptions } setSelectedOptions={ setSelectedCardOptions }/>
                </div>
                {
                  cardOptionsState.find(({ ticker }) => ticker === selectedCardOptions)?.isService === false
                    ? (
                        <CardDepositComponent
                          selectedAccount={selectedAccount}
                          setStep={setStep}
                          selectedCurrencySymbol={selectedCurrencySymbol}
                          currencySelect={currencySelect}
                          setCurrencySelect={setCurrencySelect}
                          amount={amount}
                          setAmount={setAmount}
                        />
                      )
                    : (
                        <CardServiceDepositFormComponent
                        selectedAccount={ selectedAccount }
                        selectedCardOptions={selectedCardOptions}
                         />
                      )
                }
              </div>
            </>
          : (
              <SuccessDepositFormComponent
                title={t('labels.deposit_successful')}
                onClick={ handleCloseDepositBtn }
                amount={`${amount} ${selectedCurrencySymbol}`}
                account={`${t('labels.account')} ${selectedAccount?.cfdAccountCurrency.symbol ?? ''}`}
              />
            )
      }
    </>
  )
}
