import axios, { type AxiosResponse } from 'axios';
import { type ErrorInterface } from '../../interfaces/ErrorInterface';
type ApiHelperInterface = (
  args: {
    method: string
    path: string
    data?: object | string
    params?: object | URLSearchParams | string
    responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'
    token?: string
  }) => Promise<AxiosResponse<any>>;

export const apiHelper: ApiHelperInterface = async ({
  method = '',
  path = '',
  data = '',
  params = '',
  responseType = 'json',
  token = ''
}): Promise<AxiosResponse> => {
  try {
    const response = await axios({
      method,
      url: `${process.env.REACT_APP_API_URL + path}`,
      data,
      params,
      responseType,
      headers: {
        Authorization: sessionStorage.getItem('token') ?? localStorage.getItem('token') ?? token,
        'Accept-Language': localStorage.getItem('i18nextLng') ?? 'en'
      }
    });

    return response;
  } catch (error) {
    const currentError = error as ErrorInterface;
    const uId = localStorage.getItem('uId') ?? null;

    if (!currentError.response.data.success && currentError.response.data.isTokenExpired && uId !== null) {
      localStorage.removeItem('token');
      localStorage.removeItem('uId');
      sessionStorage.removeItem('token');
      window.open('/login', '_self');
    }

    throw error;
  }
}
