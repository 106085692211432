import React, { type FC, useState, useEffect, useMemo } from 'react';
import { t } from 'i18next';

import { getFiatCurrencies } from '../../../../redux/reducers/currencies';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ButtonComponent, AssetAmountInputComponent, SearchSelectComponent, useSnackbar } from '../../../../components';
import { createPRMPayment, createDikeShieldPayment } from '../../../../redux/reducers/paymentService';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { toFixed } from '../../../../helpers/util';
import { type OptionInterface, type PayloadInterface } from '../../../../interfaces';
import style from './CardServiceDepositFormComponent.module.css';
import { handleError } from '../../../../helpers';
const nameToMethods = {
  'Card (PRM)': createPRMPayment,
  DikeShield: createDikeShieldPayment
}

interface InterfaceCardPRM {
  selectedAccount: IAccount | null
  selectedCardOptions: string
}

export const CardServiceDepositFormComponent: FC<InterfaceCardPRM> = ({
  selectedAccount,
  selectedCardOptions = ''
}) => {
  const dispatch = useAppDispatch();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();

  const [currencySelected, setCurrencySelected] = useState<OptionInterface>({
    value: '',
    label: '',
    symbol: ''
  }); ;
  const [amount, setAmount] = useState<string>('');
  const currencies = useAppSelector((state) => state.currencies);
  const paymentServices = useAppSelector((state) => state.paymentServices);
  const user = useAppSelector((state) => state.user);

  const currentService = paymentServices.find(({ name }) => name === selectedCardOptions);

  const options = useMemo(() => {
    const paymentCurrencies = currentService?.currencies !== undefined ? currentService.currencies : []

    return paymentCurrencies.map((symbol) => {
      const currCurrency = currencies.find((currency) => currency.symbol === symbol);

      return { label: currCurrency?.name ?? '', value: currCurrency?._id ?? '', symbol: currCurrency?.symbol }
    })
  }, [currencies, currentService]);

  const selectedCurrencyObj = useMemo(() => (
    currencies.find(({ _id }) => _id === currencySelected.value)
  ), [currencySelected]);

  useEffect(() => {
    dispatch(getFiatCurrencies());
  }, []);

  const handlePay = async (): Promise<void> => {
    if (selectedAccount === null || currentService === undefined || selectedCardOptions.length === 0) return;
    const namesToPayload = {
      'Card (PRM)': {
        accountId: selectedAccount._id,
        amount: toFixed(Number(amount), 2),
        currency: selectedCurrencyObj?.iso_4217 ?? '840',
        paymentId: currentService?._id ?? '',
        firstName: '',
        lastName: '',
        email: '',
        countryCode: ''
      },
      DikeShield: {
        accountId: selectedAccount._id,
        amount: toFixed(Number(amount), 2),
        currency: selectedCurrencyObj?.symbol ?? '',
        paymentId: currentService?._id ?? '',
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        email: user.email ?? '',
        countryCode: user.countryCode ?? ''
      }
    }

    const method = nameToMethods[selectedCardOptions as keyof typeof nameToMethods];
    if (method === undefined) return;
    const payloadData = namesToPayload[selectedCardOptions as keyof typeof namesToPayload];
    if (payloadData === undefined) return;

    const response = await dispatch(method(payloadData));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      handleError(payload, handleOpenSnackbar);
    }
  }

  const searchCurrencyHandler = (value: string | OptionInterface | null): void => {
    if (value === null || typeof value === 'string') return;
    setCurrencySelected(value)
  }

  const clearCurrencySelect = (): void => {
    setCurrencySelected({
      value: '',
      label: '',
      symbol: ''
    });
  }

  const formComplete = (currencySelected.value.length > 0 && amount.length > 0)

  return (
  <>
    <div className={ style.wrapper }>
      <div className={ style.formContainer }>
        <div className={ style.inputContainer }>
          <label htmlFor='selectCurrency' className={ style.label }>{t('labels.currency')}</label>
          <SearchSelectComponent
              options={ options }
              value={ currencySelected }
              onSearch={ searchCurrencyHandler }
              clearState={ clearCurrencySelect }
              id='selectCurrency'
            />
        </div>
        <div className={ style.inputContainer }>
          <label htmlFor='amount' className={ style.label }>{t('labels.amount')}</label>
            <AssetAmountInputComponent
              disabled={currencySelected.value.length === 0}
              value={ amount }
              setValue={ setAmount }
              id='amount'
              currency={selectedCurrencyObj?.symbol ?? ''}
              maxBtn={ false }
              placeholder='0.00'
            />
        </div>
      </div>
    </div>
    <footer className={ style.footer }>
      <div className={ style.btnContainer }>
        <ButtonComponent onClick={() => { handlePay(); } } disabled={ !formComplete }>
          <span className={ style.btnText }>
            {t('labels.pay')}
          </span>
        </ButtonComponent>
      </div>
    </footer>
  </>
  )
}
