import { createContext } from 'react';

export interface IProfileDialogContextProvider {
  headerContent?: React.ReactNode | null
  bodyContent?: React.ReactNode | null
  footerContent?: React.ReactNode | null
}
export const ProfileDialogContext = createContext({
  showDialog: ({ headerContent, bodyContent, footerContent }: IProfileDialogContextProvider) => {},
  hideDialog: () => {}
});
