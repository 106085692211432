import React, { type FC, useState, type ReactElement, useEffect } from 'react';

import { DepositHeaderComponent } from './DepositHeaderComponent/DepositHeaderComponent';
import { DepositSubHeaderComponent } from './DepositSubHeaderComponent/DepositSubHeaderComponent';
import { CryptoDepositFormComponent } from './CryptoDepositFormComponent/CryptoDepositFormComponent';
import { CardDepositFormComponent } from './CardDepositFormComponent/CardDepositFormComponent';
import { BankDepositFormComponent } from './BankDepositFormComponent/BankDepositFormComponent';
import { OtherDepositFormComponent } from './OtherDepositComponent/OtherDepositComponent';
import { setShowFooter } from '../../../redux/reducers/showFooter';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useGetDepositTypesQuery } from '../../../redux/api/depositTypesApi';
import { type DepositType } from '../../../redux/reducers/depositTypes';
import { modalsAssetsSpacing } from '../helper';
import { EmptyTableComponent } from '../../../components';
import { useTranslation } from 'react-i18next';
import { getBankDataForCurrentUser } from '../../../redux/reducers/psp';
import { type InterfaceDepositComponent } from '../../../interfaces/DepositComponentInterface';

import style from './DepositComponent.module.css';

export type TypesubHeaderOptions = 'Crypto' | 'Card' | 'Bank' | 'Other'

const DepositNodes = {
  Crypto: CryptoDepositFormComponent,
  Card: CardDepositFormComponent,
  Bank: BankDepositFormComponent,
  Other: OtherDepositFormComponent
};

type OptionsType = Array<{ ticker: TypesubHeaderOptions }>

const getMarginBottom = (asset: TypesubHeaderOptions | null, isMobile: boolean): string => {
  if (asset === null) return '155px';

  if (asset === 'Other') {
    return isMobile ? '50px' : '155px';
  }
  return isMobile ? `${modalsAssetsSpacing[asset]}px` : '155px';
};

export const DepositComponent: FC<InterfaceDepositComponent> = ({ setOpen, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [depositOptions, setDepositOptions] = useState<OptionsType>([]);
  const [selectedAsset, setSelectedAsset] = useState<TypesubHeaderOptions | null>(null);
  const [step, setStep] = useState<number>(1);
  const [screenWidth] = useWindowSize();
  const { showFooter } = useAppSelector((state) => state.showFooter);
  const { brandId, countryCode, _id: userId } = useAppSelector((state) => state.user);

  const isMobile = screenWidth <= 599;
  const marginBottomPx = getMarginBottom(selectedAsset, isMobile);

  const {
    data: depositData,
    error: depositError,
    isLoading: isDepositLoading
  } = useGetDepositTypesQuery({ brandId, countryCode, userId });

  useEffect(() => {
    if (isMobile && showFooter) dispatch(setShowFooter(false));
  }, []);

  useEffect(() => {
    const isDataLoadedSuccess = depositData !== undefined && depositData.success === true;
    const isDepositSuccessful = depositError === undefined;

    if (isDataLoadedSuccess && isDepositSuccessful && !isDepositLoading) {
      const options: OptionsType = depositData.data
        .map(({ name }: DepositType) => ({ ticker: name }));

      setDepositOptions(options)
    }
  }, [depositData, depositError, isDepositLoading]);

  useEffect(() => {
    const isAssetNotSelected = selectedAsset === null;
    const hasWithdrawalOptions = Array.isArray(depositOptions) && depositOptions.length > 0;

    if (isAssetNotSelected && hasWithdrawalOptions) {
      setSelectedAsset(depositOptions[0].ticker);
    }
  }, [depositOptions, selectedAsset]);

  useEffect(() => {
    if (userId !== undefined) {
      const codeCountry = countryCode ?? '';
      dispatch(getBankDataForCurrentUser({ userId, countryCode: codeCountry }));
    }
  }, [userId, countryCode]);

  const renderSelectedDepositForm = (): ReactElement => {
    if (selectedAsset !== null) {
      const Component = DepositNodes[selectedAsset];
      const props = { setOpen, step, setStep, selectedAccount };

      return <Component {...props} />;
    }

    return (
      <div className={style.noDataRow}>
        <EmptyTableComponent
          mainText={t('deposit_modal.no_data.main_text')}
          subText={t('labels.contact_manager')}
          columnCount={1}
        />
      </div>
    );
  };

  return (
    <div className={ style.mainWrapper }>
      <DepositHeaderComponent setOpen={ setOpen } selectedAccount={selectedAccount}/>
      <div
        className={ style.bodyWrapper }
        style={{ maxHeight: isMobile ? `calc(100dvh - ${marginBottomPx})` : `calc(100vh - ${marginBottomPx})` }}
      >
        {
          step === 1 && selectedAsset !== null
            ? (
                <DepositSubHeaderComponent
                  options={ depositOptions }
                  selectedOptions={ selectedAsset }
                  setSelectedOptions={ setSelectedAsset }
                />
              )
            : null
        }
        {renderSelectedDepositForm()}
      </div>
    </div>
  )
}
