import React, { type FC, useState } from 'react'
import { PasswordInputComponent, ButtonComponent, useSnackbar } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { updateUserPassword } from '../../../../redux/reducers/profileSettings';
import { sendPasswordReset } from '../../../../redux/reducers/user';
import { selectUserEmail } from '../../../../redux/selectors/user';
import { type PayloadInterface } from '../../../../interfaces';
import style from './PasswordSettings.module.css'
import { t } from 'i18next';

interface PasswordValueInterface { value: string, showPassword: boolean }

export const PasswordSettings: FC = () => {
  const dispatch = useAppDispatch()
  const { handleOpen } = useSnackbar()

  const userEmail = useAppSelector(selectUserEmail)

  const [currentPassword, setCurrentPassword] = useState<PasswordValueInterface>({ value: '', showPassword: false })
  const [newPassword, setNewPassword] = useState<PasswordValueInterface>({ value: '', showPassword: false })
  const [confirmNewPassword, setConfirmNewPassword] = useState<PasswordValueInterface>({ value: '', showPassword: false })

  const isFormActive = currentPassword.value !== '' || newPassword.value !== '' || confirmNewPassword.value !== '';
  const isSomeFieldIsEmpty = currentPassword.value === '' || newPassword.value === '' || confirmNewPassword.value === '';

  const updatePassFunction = async (): Promise<void> => {
    await dispatch(updateUserPassword({
      oldPassword: currentPassword.value,
      newPassword: newPassword.value,
      handleOpen
    }));
  }

  const completeForm = async (): Promise<void> => {
    if (isSomeFieldIsEmpty) {
      handleOpen({
        message: 'Some field is empty',
        actionText: '',
        severity: 'error'
      })
      return;
    }

    if (newPassword.value === confirmNewPassword.value) {
      await updatePassFunction();
      setCurrentPassword((prevValue) => ({ ...prevValue, value: '' }));
      setNewPassword((prevValue) => ({ ...prevValue, value: '' }));
      setConfirmNewPassword((prevValue) => ({ ...prevValue, value: '' }));
    } else {
      handleOpen({
        message: 'Passwords didn\'t match',
        actionText: '',
        severity: 'error'
      })
    }
  }

  const handleForgotPasswordBtn = async (): Promise<void> => {
    const response = await dispatch(sendPasswordReset({ email: userEmail, host: window.location.host }));
    const payload = response.payload as PayloadInterface;

    handleOpen({
      message: payload.data as string,
      actionText: '',
      severity: 'success'
    });
  }

  return (
    <>
      <div className={ style.passwordSettingsWrapper }>
        <div className={style.container}>
          <div className={ style.passwordContainer }>
            <label className={ style.passwordTitle } htmlFor='currentPassword'>{t('profile_page.settings.password_settings.labels.current_password')} *</label>
            <div className={ style.passwordInputContainer } >
              <PasswordInputComponent value={ currentPassword } setValue={ setCurrentPassword } id='currentPassword'/>
            </div>
          </div>
          <div className={ style.btnWrapper }>
            <div className={ style.btnContainer }>
              <div className={ style.btnForgotPass }>
                <ButtonComponent onClick={() => { handleForgotPasswordBtn() }} variant='text'>
                  <span className={ style.btnSpan }>
                    {t('profile_page.settings.password_settings.forgot_password_button_text')} ?
                  </span>
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
        <div className={style.container}>
          <div className={ style.passwordContainer }>
            <label className={ style.passwordTitle } htmlFor='newPassword'>{t('profile_page.settings.password_settings.labels.new_password')} *</label>
            <div className={ style.passwordInputContainer } >
              <PasswordInputComponent value={ newPassword } setValue={ setNewPassword } id='newPassword'/>
            </div>
          </div>
        </div>
        <div className={style.container}>
          <div className={ style.passwordContainer }>
            <label className={ style.passwordTitle } htmlFor='confirmNewPassword'>{t('profile_page.settings.password_settings.labels.confirm_new_password')} *</label>
            <div className={ style.passwordInputContainer } >
              <PasswordInputComponent value={ confirmNewPassword } setValue={ setConfirmNewPassword } id='confirmNewPassword'/>
            </div>
          </div>
        </div>
        <div className={ style.passwordSettingsFooterBtns }>
          <div className={ style.passwordBtn }>
            <ButtonComponent onClick={() => { completeForm(); }} variant='contained' disabled={!isFormActive}>
              {t('profile_page.settings.password_settings.save_button_text')}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </>
  )
}
