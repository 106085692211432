import React, { type FC, useState, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { ProfileDialogContext, type IProfileDialogContextProvider } from './ProfileDialogContextProvider';
import { styled } from '@mui/material/styles';
import { useWindowSize } from '../../../hooks/useWindowSize';

interface IProfileDialogContext {
  children: React.ReactNode
}

const CustomDialog = styled(Dialog)(() => ({
  ' .MuiPaper-root': {
    margin: '0',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: 'var(--CFD-theme-Surface-4)'
  },
  ' .MuiDialogContent-root': {
    padding: '0'
  },
  ' .MuiTypography-root': {
    padding: '0'
  }
}));

export const ProfileDialogContextProvider: FC<IProfileDialogContext> = ({ children }) => {
  const [screenWidth] = useWindowSize();
  const [isDialogShown, setShowDialog] = useState(false);
  const [headerContent, setHeaderContent] = useState<React.ReactNode | null>(null);
  const [bodyContent, setBodyContent] = useState<React.ReactNode | null>(null);
  const [footerContent, setFooterContent] = useState<React.ReactNode | null>(null);

  const isMobile = screenWidth < 600;

  const showDialog = ({
    headerContent, bodyContent, footerContent
  }: IProfileDialogContextProvider): void => {
    setShowDialog(true);
    if (headerContent !== undefined) setHeaderContent(headerContent);
    if (bodyContent !== undefined) setBodyContent(bodyContent);
    if (footerContent !== undefined) setFooterContent(footerContent);
  };

  const hideDialog = (): void => {
    setShowDialog(false);
    setHeaderContent(null);
    setBodyContent(null);
    setFooterContent(null);
  };

  const valueProvider = useMemo(() => ({
    showDialog,
    hideDialog
  }), []);

  return (
    <ProfileDialogContext.Provider value={valueProvider}>
      {isDialogShown &&
        (
        <CustomDialog
          fullScreen={isMobile}
          open={isDialogShown}
          onClose={() => { setShowDialog(false); }}
          sx={{
            minWidth: isMobile ? '100vw' : '560px',
            minHeight: isMobile ? '100dvh' : '560px',
            borderRadius: isMobile ? '0' : '12px',
            margin: '0 !important'
          }}
        >
          {headerContent !== null && <DialogTitle>{headerContent}</DialogTitle>}
          {bodyContent !== null && <DialogContent>{bodyContent}</DialogContent>}
          {footerContent !== null && <DialogActions sx={{ padding: 0 }}>{footerContent}</DialogActions>}
        </CustomDialog>
        )}
        { children }
    </ProfileDialogContext.Provider>
  );
}
