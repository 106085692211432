import React, { useState, useEffect, useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateSelect } from '../DateSelect/DateSelect';

interface Props {
  value?: string | null
  onChange?: (date: string | null) => void
  minDate: Date
  maxDate: Date
  onLastSelectedDayChange: (value: string | null) => void
  onLastSelectedMonthChange: (value: string | null) => void
  onLastSelectedYearChange: (value: string | null) => void
  error: boolean
  setError: (error: boolean) => void
}

const monthsTranslation: Record<string, string> = {
  1: 'months.january',
  2: 'months.february',
  3: 'months.march',
  4: 'months.april',
  5: 'months.may',
  6: 'months.june',
  7: 'months.july',
  8: 'months.august',
  9: 'months.september',
  10: 'months.october',
  11: 'months.november',
  12: 'months.december'
};

export const CustomDatePicker: FC<Props> = ({ value = null, onChange, minDate, maxDate, onLastSelectedDayChange, onLastSelectedMonthChange, onLastSelectedYearChange, error, setError }) => {
  const { t } = useTranslation();

  const [date, setDate] = useState({ day: '', month: '', year: '' });

  useEffect(() => {
    if (value) {
      const dateObj = new Date(value);
      setDate({
        day: dateObj.getDate().toString(),
        month: (dateObj.getMonth() + 1).toString(),
        year: dateObj.getFullYear().toString()
      });
    }
  }, [value]);

  const handleDateChange = (changedParams: Partial<typeof date>): void => {
    const newDate = { ...date, ...changedParams };
    setDate(newDate);

    if (newDate.day && newDate.month && newDate.year) {
      const formattedDate = `${newDate.year}-${newDate.month.toString().padStart(2, '0')}-${newDate.day.toString().padStart(2, '0')}`;
      const dateObj = new Date(formattedDate);
      const isValid = dateObj >= minDate && dateObj <= maxDate;

      setError(!isValid);
      if (isValid && onChange) onChange(formattedDate);
    }
  };

  const yearOptions = useMemo(() => {
    const years = [];
    for (let i = maxDate.getFullYear(); i >= minDate.getFullYear(); i--) {
      years.push({ label: i.toString(), value: i.toString() });
    }
    return years;
  }, [minDate, maxDate]);

  const monthOptions = useMemo(() => {
    return Object.entries(monthsTranslation).map(([value, label]) => (
      { label: t(label), value: t(value) }
    ));
  }, [t]);

  const dayOptions = useMemo(() => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push({ label: i.toString(), value: i.toString() });
    }
    return days;
  }, []);

  return (
      <div style={{ gap: '12px', display: 'flex' }}>
        <DateSelect value={date.month} onChange={(value) => { handleDateChange({ month: value }); }} options={monthOptions} defaultLabel={t('defaultLabel.month')} label={t('label.month')} error={error} onLastSelectedValueChange={onLastSelectedMonthChange} />
        <DateSelect value={date.day} onChange={(value) => { handleDateChange({ day: value }); }} options={dayOptions} defaultLabel={t('defaultLabel.day')} label={t('label.day')} error={error} onLastSelectedValueChange={onLastSelectedDayChange} />
        <DateSelect value={date.year} onChange={(value) => { handleDateChange({ year: value }); }} options={yearOptions} defaultLabel={t('defaultLabel.year')} label={t('label.year')} error={error} onLastSelectedValueChange={onLastSelectedYearChange} />
      </div>
  );
};
