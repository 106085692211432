import React, { type FC, useState, type KeyboardEvent, type ChangeEvent, useRef, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { readChat, sendMessage } from '../../redux/reducers/chat';
import { FilePickerButtonComponent, ProgressComponent, useSnackbar } from '../../components';
import { HeaderLiveChatComponent } from './HeaderLiveChatComponent/HeaderLiveChatComponent';
import { ContentLiveChatComponent } from './ContentLiveChatComponent/ContentLiveChatComponent';
import { FooterLiveChatComponent } from './FooterLiveChatComponent/FooterLiveChatComponent';
import { LiveChatPickedFileModal } from './LiveChatPickedFileModal/LiveChatPickedFileModal';
import { useBodyOverflow } from '../../hooks/useBodyOverflow';
import { selectIsRunningAdvertisement } from '../../redux/selectors/alertMessage';
import { useWindowSize } from '../../hooks/useWindowSize';

import style from './LiveChatComponent.module.css';

interface InterfaceLiveChatComponent {
  setOpen: (el: boolean) => void
  open: boolean
  setOpenDeposit?: (el: boolean) => void
  setOpenMenu?: (el: boolean) => void
  setOpenSelectAccounts?: (el: boolean) => void
}

const soundMessageSend = new Audio('/Sounds/MessageSent.mp3');

export const LiveChatComponent: FC<InterfaceLiveChatComponent> = ({ setOpen, open, setOpenDeposit, setOpenMenu, setOpenSelectAccounts }) => {
  const dispatch = useAppDispatch();
  const { _id: userId } = useAppSelector((state) => state.user);
  const isRunningAdvertisement = useAppSelector(selectIsRunningAdvertisement);
  const chat = useAppSelector((state) => state.chat);
  const { handleOpen } = useSnackbar();
  const [widthScreen] = useWindowSize();
  const isMobile = widthScreen <= 600;
  useBodyOverflow((open && isMobile));

  const [sendMessageValue, setSendMessageValue] = useState('');
  const [sendMessageFileValue, setSendMessageFileValue] = useState('');
  const [showBody, setShowBody] = useState<boolean>(true);
  const [filePicked, setFilePicked] = useState<File | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const filePickedRef = useRef<HTMLInputElement | null>(null);
  const chatBoxRef = useRef<HTMLDivElement | null>(null);

  const scrollDown = (): void => {
    if (chatBoxRef.current !== null) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const removeImageHandler = (): void => {
    setFileUrl('');
    setFilePicked(null);
    setSendMessageValue('');
    setSendMessageFileValue('');
  }

  const sendMessageHandler = async (): Promise<void> => {
    if (userId !== undefined) {
      setIsBtnLoading(true);
      const formData: FormData = new FormData();

      if (((filePickedRef.current?.files) != null) && filePicked !== null) {
        const file = filePickedRef.current.files[0];
        formData.append('file', file, file.name);
        formData.append('content', sendMessageFileValue.trim());
        formData.append('id', chat?._id?.length > 0 ? chat._id : 'undefined');
        formData.append('sender', userId);
      } else {
        formData.append('content', sendMessageValue.trim());
        formData.append('id', chat?._id?.length > 0 ? chat._id : 'undefined');
        formData.append('sender', userId);
        if (filePickedRef.current !== null) {
          filePickedRef.current.files = null;
          filePickedRef.current.value = '';
        }
      }
      await dispatch(sendMessage({ data: formData, handleOpen }));
      removeImageHandler();
      scrollDown();
      soundMessageSend.play();
      setIsBtnLoading(false);
    }
  }

  const messageValid = ((sendMessageValue.length > 0 && sendMessageValue.trim() !== '') || filePicked !== null);

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>, id: string): void => {
    const { target: { value: inputValue } } = event;
    if (id === 'caption') setSendMessageFileValue(inputValue)
    else setSendMessageValue(inputValue);
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && !isBtnLoading && messageValid) {
      event.preventDefault();
      sendMessageHandler();
    }
  }

  const handleMessageByClick = (): void => {
    if (!isBtnLoading) {
      sendMessageHandler();
    }
  }

  const filePickerHandler = (inputElement: HTMLInputElement | null): void => {
    if (inputElement !== null) {
      inputElement.click();
    }
  }

  useEffect(() => {
    setLoader(true);
    setIsBtnLoading(!chat.isUserCanSendAMessage)
    setLoader(false);
  }, [chat.isUserCanSendAMessage]);

  const readMessage = async (): Promise<void> => {
    if (userId !== undefined) {
      const data = {
        chatId: chat._id,
        content: sendMessageValue,
        sender: userId
      }
      await dispatch(readChat(data));
      scrollDown();
    }
  }

  useEffect(() => {
    if (chat?.messages.length > 0 && showBody) {
      readMessage();
    }
  }, [chat?.messages, showBody])

  return (
    <div className={ style.liveChatWrapper } style={{ bottom: isRunningAdvertisement ? '104px' : '56px' }}>
      <HeaderLiveChatComponent
        setOpen={ setOpen }
        setShowBody={ setShowBody }
        setOpenDeposit={ setOpenDeposit ?? (() => {}) }
        setOpenMenu={ setOpenMenu ?? (() => {}) }
        setOpenSelectAccounts={ setOpenSelectAccounts ?? (() => {}) }
      />
      { showBody
        ? <>
        { loader
          ? <ProgressComponent type='circular' />
          : <ContentLiveChatComponent ref={ chatBoxRef }/>
        }
        {fileUrl?.length > 0 && (
          <LiveChatPickedFileModal
            sendMessageFileValue={ sendMessageFileValue }
            inputChangeHandler={ inputChangeHandler }
            handleKeyDown={ handleKeyDown }
            sendMessageHandler={ handleMessageByClick }
            messageValid={ messageValid }
            removeImageHandler={ removeImageHandler }
            filePicked={ filePicked }
            fileUrl={ fileUrl }
            isBtnLoading= { isBtnLoading }
          />
        )}
        <div className={ style.footerLiveChat }>
          <div className={ style.inputWrapper }>
            <FilePickerButtonComponent
              ref={ filePickedRef }
              setFileUrl={ setFileUrl }
              handleFileChange={ setFilePicked }
              handleDocsPicker={ filePickerHandler }
              id='chatFile'
            />
            <FooterLiveChatComponent
              sendMessageValue={ sendMessageValue }
              inputChangeHandler={ inputChangeHandler }
              handleKeyDown={ handleKeyDown }
              sendMessageHandler={ handleMessageByClick }
              messageValid={ messageValid }
              isBtnLoading= { isBtnLoading }
              id='message'
            />
          </div>
        </div>
      </>
        : null }
    </div>
  )
}
