import React, { type FC } from 'react';
import { SectionSuccessModal } from '../../../components';

import style from './SectionModalTransferFunds.module.css';
import { formatDate } from '../../../helpers/util';
import { t } from 'i18next';

interface InterfaceSectionModalTransferFunds {
  step: number
  fromValue: number
  toValue: number
  fromSymbol: string
  toSymbol: string
}

export const SectionModalTransferFunds: FC<InterfaceSectionModalTransferFunds> = ({ step, fromValue, toValue, fromSymbol, toSymbol }) => {
  return (
    <section className={ style.section }>
      {step === 1
        ? <div className={ style.transferInfoWrapper }>
            <div className={ style.transferInfo }>
              <p className={ style.transferInfoTitle }>{t('labels.transfer')}</p>
              <div className={ style.amountContainer }>
                <span className={ style.fromAmount }>{`${fromValue} ${fromSymbol}`}</span>
                <span className={ style.toAmount }>{`= ${toValue} ${toSymbol}`}</span>
              </div>
            </div>
            <div className={ style.rateWrapper }>
              <p className={ style.rateTitle }>{t('labels.exchange_rate')}</p>
              <div className={ style.rateAmountContainer }>
                <span className={ style.fromRate }>{`${fromValue} ${fromSymbol} =`}</span>
                <span className={ style.toRate }>{`${toValue} ${toSymbol}`}</span>
              </div>
              {/* <p className={ style.rateText }>Exchange Fee: 0%</p> */}
            </div>
          </div>
        : <div className={ style.successWrapper }>
            <SectionSuccessModal title={ t('labels.transfer_successful') }/>
            <div className={ style.successSummary }>
              <div className={ style.summuryContainer }>
                <p className={ style.summaryLabel }>{t('labels.from_account')}</p>
                <p className={ style.summaryValue }>{`${t('labels.account')} ${fromSymbol}`}</p>
              </div>
              <div className={ style.summuryContainer }>
                <p className={ style.summaryLabel }>{t('labels.transfer')}</p>
                <p className={ style.summaryValue }>{`${fromValue} ${fromSymbol}`}</p>
              </div>
              <div className={ style.summuryContainer }>
                <p className={ style.summaryLabel }>{t('labels.exchange_rate')}</p>
                <p className={ style.summaryValue }>{`${fromValue} ${fromSymbol} = ${toValue} ${toSymbol}`}</p>
              </div>
              <div className={ style.summuryContainer }>
                <p className={ style.summaryLabel }>{t('labels.date')}</p>
                <p className={ style.summaryValue }>{formatDate(new Date())}</p>
              </div>
            </div>
          </div>}
    </section>
  )
}
