export enum ProfileElementHeight {
  PaddingTop = 16,
  Footer = 64,
  Header = 52,
  Gap = 24,
  BalanceChartSection = 361,
  StatisticSection = 276,
  TradingChartSection = 403,
  Border = 2,
  TableStatisticSection = 556,
  DocumentSectionHeader = 64, // some times 92, try on mobile with different language
  DocumentSectionFooter = 130
}
